(function() {
	'use strict';

	angular.module('api.studentdata', ['api.urls', 'utils.timed_cache', 'ngResource']).service('StudentData', StudentData);

	StudentData.$inject = ['$resource', 'TimedCache', 'urls'];
	function StudentData($resource, TimedCache, urls) {
		var resource,
			baseUrl = urls.sequencerApiUrl + 'studentdata',
			studentData = this;

		studentData.assignBoosterBits = assignBoosterBits;
		activate();

		////////////////

		function activate() {
			var actions = {
				assignBoosterBits: {
					method: 'POST',
					params: {
						studentId: '@studentId'
					},
					url: baseUrl + '/teacher/StudentData/:studentId'
				}
			};

			resource = $resource(baseUrl, null, TimedCache.enableCaching(actions));
		}

		function assignBoosterBits(artifactId, studentId, activity, amount) {
			return resource.assignBoosterBits({
				artifactId: artifactId,
				studentId: studentId,
				activity: activity,
				amount: amount
			}).$promise;
		}
	}
})();
