//helper for common bulk ops functionality

(function() {
	angular
		.module('api.manager.bulk.operations.job', ['api.manager.bulk.operations.job.types'])
		.factory('ManagerBulkOperationsJob', bulkOperationsJobFactory);

	bulkOperationsJobFactory.$inject = ['BulkOperationsJobTypes'];

	function bulkOperationsJobFactory(BulkOperationsJobTypes) {
		function getJobType(jobType) {
			if (jobType.match('Patch')) {
				return 'Patch';
			}
			return jobType
				.replace('CreateOrUpdate', '')
				.replace('Archive', '')
				.replace('Delete', '')
				.replace('Tree', '');
		}

		return {
			get: function(jobType, items) {
				var job = [],
					entityType = getJobType(jobType);

				angular.forEach(items, function(item) {
					var params = {};
					params['BulkOperationType'] = jobType;
					params[entityType] = item;
					job.push(params);
				});

				return job;
			}
		};
	}
})();
