/*

retrieves audit log for any entity type using the supplied id

*/

(function(angular, undefined) {
	angular.module('api.audit.history', ['api.urls', 'api.helpers', 'utils.timed_cache', 'ngResource']).factory('AuditHistory', factory);

	factory.$inject = ['$resource', 'APIHelpers', 'TimedCache', 'urls'];

	function factory($resource, APIHelpers, TimedCache, urls) {
		var resourceUrl = 'history/:id';
		var url = urls.managementApiUrl + resourceUrl;

		var actions = {
			query: { method: 'GET', transformResponse: APIHelpers.convertToCountAndItems }
		};

		var cache = TimedCache.create(15 * 1000);

		var resource = $resource(url, null, TimedCache.enableCaching(actions, cache));

		return {
			query: function(params) {
				params = params || {};
				return resource.query(params).$promise;
			}
		};
	}
})(window.angular);
