angular.module('api.districtStudentId', ['api.urls', 'ngResource']).factory('DistrictStudentIdService', DistrictStudentIdFactory);

DistrictStudentIdFactory.$inject = ['$resource', 'urls'];

function DistrictStudentIdFactory($resource, urls) {
	const resourceUrl = 'availability/districtstudentid/';
	const url = urls.managementApiUrl + resourceUrl;
	const actions = {
		checkAvailability: {
			method: 'POST',
			isArray: true
		}
	};

	let resource = $resource(url, null, actions);

	return {
		checkAvailability: function(districtStudentIds, siteCode) {
			if (!_.isArray(districtStudentIds)) {
				districtStudentIds = [districtStudentIds];
			}
			return resource.checkAvailability({ districtStudentIds, siteCode }).$promise;
		}
	};
}
