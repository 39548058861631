// bulk operations for manager api entities
import { BulkApi } from '@imaginelearning/web-services';

angular
	.module('api.manager.bulk.operations', ['react.dependencies'])
	.constant('BulkApi', BulkApi)
	.factory('ManagerBulkOperations', bulkOperationsFactory);

bulkOperationsFactory.$inject = ['BulkApi', '$q', 'ReactDependenciesService'];

function bulkOperationsFactory(BulkApi, $q, ReactDependenciesService) {
	const bulkApi = new BulkApi(ReactDependenciesService.apiConfig());

	return {
		//Patch
		//PatchOrganization
		//PatchGroup
		//PatchUser
		//PatchStudent
		patch: {
			organizations: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.patchOrganizations(items).toPromise();
			},
			groups: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.patchGroups(items).toPromise();
			},
			users: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.patchUsers(items).toPromise();
			},
			students: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.patchStudents(items).toPromise();
			}
		},

		//patchOrgsAndGroups
		//PatchAddToUser
		//PatchAddToStudent
		patchOrgsAndGroups: {
			users: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.patchUsers(items).toPromise();
			},
			students: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.patchAddToStudents(items).toPromise();
			}
		},

		//BulkOperationType:
		//CreateOrUpdateOrganization,
		//CreateOrUpdateUser,
		//CreateOrUpdateGroup,
		//CreateOrUpdateStudent,
		createOrUpdate: {
			// organizations: function (items) {
			// 	if (!items || !items.length) { return $q.reject({ error: 'Invalid array' }); }
			// 	return bulkApi.createOrganizations(items).toPromise();
			// },
			groups: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.createGroups(items).toPromise();
			},
			staff: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.createUsers(items).toPromise();
			},
			students: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.createStudents(items).toPromise();
			}
		},

		//BulkOperationType:
		//DeleteOrganization,
		//DeleteUser,
		//DeleteGroup,
		//DeleteStudent,
		//DeleteOrganizationTree
		delete: {
			// organizations: function(items, async) {
			// 	if (!items || !items.length) {
			// 		return $q.reject({ error: 'Invalid array' });
			// 	}
			// 	// var params = { async: async && async === true ? true : false };
			// 	// return resource.submitJob(params, ManagerBulkOperationsJob.get(BulkOperationsJobTypes.DeleteOrganization, items))
			// 	// 	.$promise;
			// 		return bulkApi.archiveStudents(items).toPromise();
			// },
			groups: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.deleteGroups(items).toPromise();
			},
			staff: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.deleteUsers(items).toPromise();
			},
			students: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.archiveStudents(items).toPromise();
			}
		},

		//BulkOperationType:
		//ArchiveStudent
		archive: {
			students: function(items) {
				if (!items || !items.length) {
					return $q.reject({ error: 'Invalid array' });
				}
				return bulkApi.archiveStudents(items).toPromise();
			}
		}
	};
}
