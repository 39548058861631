//helper methods for common bulk ops functionality - testable and reusable in it's own factory

(function() {
	angular.module('api.manager.bulk.operations.job.types', []).constant('BulkOperationsJobTypes', {
		CreateOrUpdateOrganization: 'CreateOrUpdateOrganization',
		CreateOrUpdateUser: 'CreateOrUpdateUser',
		CreateOrUpdateGroup: 'CreateOrUpdateGroup',
		CreateOrUpdateStudent: 'CreateOrUpdateStudent',
		DeleteOrganization: 'DeleteOrganization',
		DeleteUser: 'DeleteUser',
		DeleteGroup: 'DeleteGroup',
		DeleteStudent: 'DeleteStudent',
		DeleteOrganizationTree: 'DeleteOrganizationTree',
		ArchiveOrganization: 'ArchiveOrganization',
		ArchiveStudent: 'ArchiveStudent',
		PatchOrganization: 'PatchOrganization',
		PatchUser: 'PatchUser',
		PatchGroup: 'PatchGroup',
		PatchStudent: 'PatchStudent',
		PatchAddToUser: 'PatchAddToUser',
		PatchAddToStudent: 'PatchAddToStudent'
	});
})();

/*  Synchronous Job Result

{
	"id": "a2b73263-7fe2-4c98-923e-e21fe70ba398",
	"status": "FinishedAllSuccess",
	"organizationsCreated": 0,
	"organizationsUpdated": 0,
	"organizationsDeleted": 0,
	"usersCreated": 0,
	"usersUpdated": 0,
	"usersDeleted": 0,
	"groupsCreated": 0,
	"groupsUpdated": 0,
	"groupsDeleted": 0,
	"studentsCreated": 0,
	"studentsUpdated": 0,
	"studentsDeleted": 2,
	"results": [
		{
			"id": "2c3ff59b-8a93-4c66-8aa9-a567010f754e",
			"status": "Deleted"
		},
		{
			"id": "90d1df81-17e8-416e-896d-a567010f7565",
			"status": "Deleted"
		}
	]
}

*/
