(function() {
	angular
		.module('api.lti.token', [
			'api.urls',
			// 'api.helpers',
			// 'utils.timed_cache',
			'ngResource'
		])
		.service('LTIToken', service);

	service.$inject = [
		'$resource',
		'urls'
		// 'APIHelpers',
		// 'TimedCache'
	];

	function service(
		$resource,
		urls
		// APIHelpers
		// TimedCache
	) {
		var service = this;

		service.get = get;

		var resourceUrl = 'ltitoken';
		var url = urls.unVersionedManagementApiUrl + resourceUrl;

		var actions = {
			get: {
				url: url,
				method: 'GET'
			}
		};

		// var resource = $resource(url, null, TimedCache.enableCaching(actions));
		var resource = $resource(url, null, actions);

		function get(params) {
			params = params || {};
			return resource.get(params).$promise;
		}
	}
})();
