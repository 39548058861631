(function(angular, undefined) {
	angular
		.module('api.reports.skills', [
			'api.urls',
			'api.helpers',
			'reporting.api.helpers',
			'product.names',
			'utils.date',
			'utils.timed_cache',
			'ngResource'
		])
		.factory('Skills', factory);

	factory.$inject = ['$resource', 'ApiProducts', 'APIHelpers', 'ReportingAPIHelpers', 'DateUtils', 'TimedCache', 'urls'];

	function transformStudentByDateResponseToCountAndItems(data, headersGetter) {
		var response = JSON.parse(data),
			items = _.get(response, 'entries', []);
		var count = items ? items.length : 0;
		return {
			totalCount: +headersGetter('X-Total-Count') || count,
			items: items
		};
	}

	function factory($resource, ApiProducts, APIHelpers, ReportingAPIHelpers, DateUtils, TimedCache, urls) {
		var skillsBaseUrl = urls.reportApiUrl;

		var actions = {
			byCurriculumArea: {
				cancellable: true,
				method: 'GET',
				url: skillsBaseUrl + 'students/:id/skills/'
			},

			byDate: {
				cancellable: true,
				method: 'GET',
				url: skillsBaseUrl + 'students/:id/skillsbydate/'
			},

			byDateToCountAndItems: {
				cancellable: true,
				method: 'GET',
				url: skillsBaseUrl + 'students/:id/skillsbydate/',
				transformResponse: transformStudentByDateResponseToCountAndItems
			}
		};

		var cache = TimedCache.create(TimedCache.minutes(5));
		var resource = $resource(skillsBaseUrl, null, TimedCache.enableCaching(actions, cache));

		return {
			student: {
				byCurriculumArea: function(params) {
					return resource.byCurriculumArea({
						curriculumArea: params.curriculumArea || 'all',
						resultType: params.resultType || 'all',
						id: params.studentId,
						start: DateUtils.formatForUrl(params.start),
						end: DateUtils.formatForUrl(params.end),
						product: params.product ? params.product : ApiProducts.ILE
					});
				},

				byDate: function(params) {
					return resource.byDate({
						curriculumArea: params.curriculumArea || 'all',
						resultType: params.resultType || 'all',
						id: params.studentId,
						start: DateUtils.formatForUrl(params.start),
						end: DateUtils.formatForUrl(params.end),
						product: params.product ? params.product : ApiProducts.ILE
					});
				},

				byDateToCountAndItems: function(params) {
					return resource.byDateToCountAndItems({
						limit: params.limit,
						offset: params.offset,
						curriculumArea: params.curriculumArea || 'all',
						resultType: params.resultType || 'all',
						id: params.studentId,
						start: DateUtils.formatForUrl(params.start),
						end: DateUtils.formatForUrl(params.end),
						product: params.product ? params.product : ApiProducts.ILE
					});
				}
			}
		};
	}
})(window.angular);
