(function() {
	angular
		.module('reporting.api', [
			'api.urls',
			'api.application.user',
			'utils.date',
			'ngResource',
			'api.helpers',
			'reporting.api.helpers',
			'utils.timed_cache'
		])
		.factory('ReportingAPI', factory);

	factory.$inject = ['$resource', 'urls', 'ApplicationUserService', 'APIHelpers', 'ReportingAPIHelpers', 'TimedCache'];

	function factory($resource, urls, ApplicationUserService, APIHelpers, ReportingAPIHelpers, TimedCache) {
		var url = urls.reportsApiUrl,
			unassignedPrefix = 'unassigned_';

		var actions = {
			// organizations
			organizations: {
				cancellable: true,
				method: 'GET',
				url: url + 'organizations',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			organization: {
				cancellable: true,
				method: 'GET',
				url: url + 'organizations/:orgId'
			},

			childOrganizations: {
				cancellable: true,
				method: 'GET',
				url: url + 'organizations/:orgId/children',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			childGroups: {
				cancellable: true,
				method: 'GET',
				url: url + 'groups/:orgId',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			groupsFromOrg: {
				cancellable: true,
				method: 'GET',
				url: url + 'groups/organization/:orgId',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			unassignedGroup: {
				cancellable: true,
				method: 'GET',
				url: url + 'groups/unassigned/:orgId'
			},

			unassignedGroupList: {
				cancellable: true,
				method: 'GET',
				url: url + 'groups/unassigned/:orgId',
				transformResponse: APIHelpers.reportingAPIObjectToCountAndItems
			},

			// gradeLevels
			gradeLevels: {
				cancellable: true,
				method: 'GET',
				url: url + 'grade-levels/org/:orgId',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			// groups
			groups: {
				cancellable: true,
				method: 'GET',
				url: url + 'groups',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			group: {
				cancellable: true,
				method: 'GET',
				url: url + 'groups/:groupId'
			},

			groupById: {
				cancellable: true,
				method: 'GET',
				url: url + 'groups/id/:groupId'
			},

			groupStudents: {
				cancellable: true,
				method: 'GET',
				url: url + 'students/group/:groupId',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			groupStudentsBenchmarkUnwrapped: {
				cancellable: true,
				method: 'GET',
				url: url + 'students/group/:groupId',
				transformResponse: ReportingAPIHelpers.transformResponseBenchmark
			},

			groupUnassignedStudentsBenchmarkUnwrapped: {
				cancellable: true,
				method: 'GET',
				url: url + 'students/unassigned/:orgId',
				transformResponse: ReportingAPIHelpers.transformResponseBenchmark
			},

			groupStudentProgressByLesson: {
				cancellable: true,
				method: 'GET',
				url: url + 'students/progressbylesson/:groupId',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			groupUnassignedStudentProgressByLesson: {
				cancellable: true,
				method: 'GET',
				url: url + 'students/progressbylesson/unassigned/:groupId',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			// students
			students: {
				cancellable: true,
				method: 'GET',
				url: url + 'students',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			unassignedStudents: {
				cancellable: true,
				method: 'GET',
				url: url + 'students/unassigned/:orgId',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			student: {
				cancellable: true,
				method: 'GET',
				url: url + 'students/:studentId',
				isArray: false
			},

			studentProgressByLesson: {
				cancellable: true,
				method: 'GET',
				url: url + 'students/progressbylesson/student/:studentId'
			},

			studentLessonDetails: {
				cancellable: true,
				method: 'GET',
				url: url + 'students/lessonDetails/:studentId'
			},

			// user
			userOrganizations: {
				cancellable: true,
				method: 'GET',
				url: url + 'organizations/user/:userId',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			userGroups: {
				cancellable: true,
				method: 'GET',
				url: url + 'groups/user/:userId',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			},

			userStudents: {
				cancellable: true,
				method: 'GET',
				url: url + 'students/user/:userId',
				transformResponse: APIHelpers.reportingAPIToCountAndItems
			}
		};

		var cache = TimedCache.create(TimedCache.minutes(5));
		var resource = $resource(url, null, TimedCache.enableCaching(actions, cache));

		return {
			exportUrls: {
				organization: {
					children: function(params) {
						return [
							url,
							'organizations/',
							params.id,
							'/children/csv',
							'?startdatemmddyyyy=',
							APIHelpers.dateFilter(params.startDate),
							'&enddatemmddyyyy=',
							APIHelpers.dateFilter(params.endDate),
							params.dataFields && params.dataFields.length ? '&dataFields=' + params.dataFields.join('&dataFields=') : '',
							'&access_token=',
							ApplicationUserService.getToken()
						].join('');
					},

					groups: function(params) {
						return [
							url,
							'groups/',
							params.id,
							'/csv',
							'?startdatemmddyyyy=',
							APIHelpers.dateFilter(params.startDate),
							'&enddatemmddyyyy=',
							APIHelpers.dateFilter(params.endDate),
							params.dataFields && params.dataFields.length ? '&dataFields=' + params.dataFields.join('&dataFields=') : '',
							'&access_token=',
							ApplicationUserService.getToken()
						].join('');
					}
				},

				group: {
					students: function(params) {
						return [
							url,
							'students/group/',
							params.id,
							'/csv',
							'?startdatemmddyyyy=',
							APIHelpers.dateFilter(params.startDate),
							'&enddatemmddyyyy=',
							APIHelpers.dateFilter(params.endDate),
							params.dataFields && params.dataFields.length ? '&dataFields=' + params.dataFields.join('&dataFields=') : '',
							'&access_token=',
							ApplicationUserService.getToken()
						].join('');
					}
				}
			},

			organizations: function(params) {
				return resource.organizations({
					startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
					enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
					offset: params.offset || 0,
					limit: params.limit || 10,
					search: params.search || undefined,
					sortby: params.sortby || undefined,
					dataFields: params.dataFields || undefined,
					grade: params.grade || undefined,
					desc: params.desc || false
				}).$promise;
			},

			gradeLevels: function(params) {
				return resource.gradeLevels({
					orgId: params.id,
					startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
					enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
					// offset: params.offset || 0,
					// limit: params.limit || 10,
					search: params.search || undefined,
					// sortby: params.sortby || undefined,
					// desc: params.desc || false,
					dataFields: params.dataFields || undefined
				}).$promise;
			},

			groups: function(params) {
				if (params.search && params.search.length && params.search.match(unassignedPrefix)) {
					return resource.unassignedGroupList({
						orgId: params.search.replace(unassignedPrefix, ''),
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						dataFields: params.dataFields || undefined
					}).$promise;
				} else {
					return resource.groups({
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						offset: params.offset || 0,
						limit: params.limit || 10,
						search: params.search || undefined,
						sortby: params.sortby || undefined,
						dataFields: params.dataFields || undefined,
						grade: params.grade || undefined,
						desc: params.desc || false
					}).$promise;
				}
			},

			students: function(params) {
				return resource.students({
					startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
					enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
					offset: params.offset || 0,
					limit: params.limit || 10,
					search: params.search || undefined,
					sortby: params.sortby || undefined,
					dataFields: params.dataFields || undefined,
					grade: params.grade || undefined,
					desc: params.desc || false
				}).$promise;
			},

			organization: {
				get: function(params) {
					return resource.organization({
						orgId: params.id,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						grade: params.grade || undefined,
						dataFields: params.dataFields || undefined
					}).$promise;
				},

				getResource: function(params) {
					return resource.organization({
						orgId: params.id,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						grade: params.grade || undefined,
						dataFields: params.dataFields || undefined
					});
				},

				children: function(params) {
					return resource.childOrganizations({
						orgId: params.id,
						grade: params.grade,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						offset: params.offset || 0,
						limit: params.limit || 10,
						search: params.search || undefined,
						sortby: params.sortby || undefined,
						dataFields: params.dataFields || undefined,
						desc: params.desc || false
					});
				},

				organizations: function(params) {
					return resource.childOrganizations({
						orgId: params.id,
						grade: params.grade,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						offset: params.offset || 0,
						limit: params.limit || 10,
						search: params.search || undefined,
						sortby: params.sortby || undefined,
						dataFields: params.dataFields || undefined,
						desc: params.desc || false
					});
				},

				groupsFromOrg: function(params) {
					return resource.groupsFromOrg({
						orgId: params.id,
						grade: params.grade,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						offset: params.offset || 0,
						limit: params.limit || 10,
						search: params.search || undefined,
						sortby: params.sortby || undefined,
						dataFields: params.dataFields || undefined,
						desc: params.desc || false
					});
				},

				groups: function(params) {
					return resource.childGroups({
						orgId: params.id,
						grade: params.grade,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						offset: params.offset || 0,
						limit: params.limit || 10,
						search: params.search || undefined,
						sortby: params.sortby || undefined,
						dataFields: params.dataFields || undefined,
						desc: params.desc || false
					});
				},

				unassignedGroup: function(params) {
					return resource.unassignedGroup({
						orgId: params.id,
						grade: params.grade,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						// offset: params.offset || 0,
						// limit: params.limit || 10,
						// search: params.search || undefined,
						// sortby: params.sortby || undefined,
						dataFields: params.dataFields || undefined
						// desc: params.desc || false
					});
				},

				unassignedStudents: function(params) {
					return resource.unassignedStudents({
						orgId: params.id,
						grade: params.grade,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						offset: params.offset || 0,
						limit: params.limit || 10,
						search: params.search || undefined,
						sortby: params.sortby || undefined,
						dataFields: params.dataFields || undefined,
						desc: params.desc || false
					});
				}
			},

			group: {
				byId: function(params) {
					return resource.groupById({
						groupId: params.id,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						grade: params.grade || undefined,
						dataFields: params.dataFields || undefined
					}).$promise;
				},

				get: function(params) {
					if (params.id && params.id.length && params.id.match(unassignedPrefix)) {
						return resource.unassignedGroup({
							orgId: params.id.replace(unassignedPrefix, ''),
							startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
							enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
							dataFields: params.dataFields || undefined
						}).$promise;
					} else {
						return resource.groupById({
							groupId: params.id,
							grade: params.grade || undefined,
							startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
							enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
							dataFields: params.dataFields || undefined
						}).$promise;
					}
				},

				getResource: function(params) {
					return resource.groupById({
						groupId: params.id,
						grade: params.grade || undefined,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						dataFields: params.dataFields || undefined
					});
				},

				students: function(params) {
					if (params.id && params.id.length && params.id.match(unassignedPrefix)) {
						return resource.unassignedStudents({
							orgId: params.id.replace(unassignedPrefix, ''),
							grade: params.grade,
							startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
							enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
							offset: params.offset || 0,
							limit: params.limit || 10,
							search: params.search || undefined,
							sortby: params.sortby || undefined,
							dataFields: params.dataFields || undefined,
							desc: params.desc || false
						}).$promise;
					} else {
						return resource.groupStudents({
							groupId: params.id,
							grade: params.grade || undefined,
							startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
							enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
							offset: params.offset || 0,
							limit: params.limit || 10,
							search: params.search || undefined,
							sortby: params.sortby || undefined,
							dataFields: params.dataFields || undefined,
							desc: params.desc || false
						}).$promise;
					}
				},

				studentBenchmarkResults: function(params) {
					var resourceParams = {
						grade: params.grade,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						offset: params.offset || 0,
						limit: params.limit || 10,
						search: params.search || undefined,
						sortby: params.sortby || undefined,
						dataFields: params.dataFields || undefined,
						desc: params.desc || false
					};

					if (params.id && params.id.length && params.id.match(unassignedPrefix)) {
						resourceParams.orgId = params.id.replace(unassignedPrefix, '');
						return resource.groupUnassignedStudentsBenchmarkUnwrapped(resourceParams);
					} else {
						resourceParams.groupId = params.id;
						return resource.groupStudentsBenchmarkUnwrapped(resourceParams);
					}
				},

				studentProgressByLesson: function(params) {
					var id = params.id || '',
						unassignedMatch = id.match(/^unassigned_(.+)/i),
						resourceParams = {
							groupId: unassignedMatch ? unassignedMatch.slice(1, 2).shift() : id,
							startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
							enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
							offset: params.offset || 0,
							limit: params.limit || 10,
							search: params.search || undefined,
							sortby: params.sortby || undefined,
							// dataFields: params.dataFields || undefined,
							desc: params.desc || false
						};

					if (unassignedMatch) {
						return resource.groupUnassignedStudentProgressByLesson(resourceParams);
					}

					return resource.groupStudentProgressByLesson(resourceParams);
				}
			},

			student: {
				get: function(params) {
					return resource.student({
						studentId: params.id,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						dataFields: params.dataFields || undefined
					}).$promise;
				},

				getResource: function(params) {
					return resource.student({
						studentId: params.id,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						dataFields: params.dataFields || undefined
					});
				},

				progressByLesson: function(params) {
					return resource.studentProgressByLesson({
						studentId: params.id,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate)
					});
				},

				lessonDetails: function(params) {
					return resource.studentLessonDetails({
						studentId: params.id,
						curriculum: params.curriculum,
						gradeLevel: params.gradeLevel,
						gradeGroup: params.gradeGroup,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate)
					});
				}
			},

			user: {
				organizations: function(params) {
					return resource.userOrganizations({
						userId: params.id,
						orgId: params.orgId,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						offset: params.offset || 0,
						limit: params.limit || 10,
						dataFields: params.dataFields || undefined,
						search: params.search || undefined,
						sortby: params.sortby || undefined,
						desc: params.desc || false
					}).$promise;
				},

				groups: function(params) {
					return resource.userGroups({
						userId: params.id,
						orgId: params.orgId,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						offset: params.offset || 0,
						limit: params.limit || 10,
						search: params.search || undefined,
						dataFields: params.dataFields || undefined,
						sortby: params.sortby || undefined,
						desc: params.desc || false
					}).$promise;
				},

				students: function(params) {
					return resource.userStudents({
						userId: params.id,
						orgId: params.orgId,
						startdatemmddyyyy: APIHelpers.dateFilter(params.startDate),
						enddatemmddyyyy: APIHelpers.dateFilter(params.endDate),
						offset: params.offset || 0,
						limit: params.limit || 10,
						search: params.search || undefined,
						dataFields: params.dataFields || undefined,
						sortby: params.sortby || undefined,
						desc: params.desc || false
					}).$promise;
				}
			}
		};
	}
})();
