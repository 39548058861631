(function() {
	angular.module('api.sso', ['angular-jwt', 'api.urls', 'product.names', 'utils.client.detector']).service('SsoApi', ssoApiService);

	ssoApiService.$inject = ['$http', '$log', '$q', 'ApiProducts', 'ClientDetector', 'Clients', 'jwtHelper', 'urls'];

	function ssoApiService($http, $log, $q, ApiProducts, ClientDetector, Clients, jwtHelper, urls) {
		this.authenticateOauthRequest = authenticateOauthRequest;
		this.getRedirectUrl = getRedirectUrl;
		this.getStudentLaunchUrl = getStudentLaunchUrl;
		this.getPortalUrl = getPortalUrl;

		this.getPreferredClient = getPreferredClient; // exposed for testing

		////////////////

		function authenticateOauthRequest(code, realmName, redirectUri) {
			if (!code) {
				return $q.reject({
					data: {
						status: 400,
						error: 'Bad Request',
						message: 'Code is missing'
					}
				});
			}

			return $http.get(
				urls.singleSignOnApiUrl +
					'singlesignon/' +
					realmName +
					'/' +
					code +
					'/AuthenicateOauthRequest?redirectUri=' +
					encodeURIComponent(redirectUri)
			);
		}

		function getIntendedProduct(jwt) {
			if (typeof jwt === 'string' && /^eyJ[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/.test(jwt)) {
				const decodedToken = jwtHelper.decodeToken(jwt) || {};
				return _.get(decodedToken, 'http://il/requestedProduct');
			}
			return null;
		}

		function getRedirectUrl(siteCode) {
			//this is for service provider initiated sso. This method most likely will be moved to portal.
			if (!siteCode) {
				return $q.reject({
					data: {
						status: 400,
						error: 'Bad Request',
						message: 'sitecode cannot be null'
					}
				});
			}
			//web call
			return $http.get(urls.singleSignOnApiUrl + 'singlesignon/' + encodeURIComponent(siteCode) + '/RealmRedirectUrl');
		}

		function getStudentLaunchUrl(ssoDetails, platform) {
			const product = getIntendedProduct(ssoDetails.EncryptedToken);
			const playerClient = ClientDetector.getClient();
			if ((!product || product === ApiProducts.ILE || product === ApiProducts.Spanish) && playerClient !== Clients.html5) {
				return getPreferredClient(ssoDetails.EncryptedToken, ssoDetails.Signature, ssoDetails.SiteCode)
					.then(function(client) {
						return getLaunchUrl(ssoDetails, platform, client);
					})
					.catch(function(err) {
						$log.error(err);
						return getLaunchUrl(ssoDetails, platform);
					});
			}

			return $q.when(`${urls.authApiUrl}/productSelection`);
		}

		function getLaunchUrl(ssoDetails, platform, client) {
			var query = [],
				prefix = platform === 'Android' ? '' : 'il_',
				siteCode = ssoDetails.SiteCode;

			if (urls.environment === 'development') {
				siteCode += '@test';
			} else if (urls.environment !== 'production') {
				siteCode += '@' + urls.environment;
			}

			query.push(prefix + 'responseDetails=' + encodeURIComponent(ssoDetails.EncryptedToken));
			query.push(prefix + 'signature=' + encodeURIComponent(ssoDetails.Signature));
			query.push(prefix + 'role=' + encodeURIComponent(ssoDetails.Role));
			query.push(prefix + 'sitecode=' + encodeURIComponent(siteCode));
			query.push(prefix + 'enablehttps=true');
			if (client) {
				query.push(prefix + 'client=' + encodeURIComponent(client));
			}

			return urls.unityLaunchUrl.replace(/\/$/, '') + '/?' + query.join('&');
		}

		function getPortalUrl(ssoDetails) {
			var portalUrl = urls.portalUrl;
			portalUrl += '?responseDetails=' + encodeURIComponent(ssoDetails.EncryptedToken);
			portalUrl += '&signature=' + encodeURIComponent(ssoDetails.Signature);

			return portalUrl;
		}

		function getPreferredClient(encryptedToken, sitecode) {
			return getPortalSettings(sitecode, encryptedToken).then(function(settings) {
				if (settings && settings.hasOwnProperty('useNewHtml5Client')) {
					return settings.useNewHtml5Client ? 'new' : 'legacy';
				}
				return undefined;
			});
		}

		function getPortalSettings(sitecode, token) {
			var url = urls.managementApiUrl + 'portalsettings';
			if (!token && sitecode) {
				url += '?sitecode=' + encodeURIComponent(sitecode);
			}
			return httpGet(url, token).then(function(response) {
				return (response && response.data && response.data.enabledDevelopmentFeatures) || {};
			});
		}

		function httpGet(url, token) {
			var headers = token ? { headers: { Authorization: 'bearer ' + token } } : undefined;
			return $http.get(url, headers);
		}
	}
})();
