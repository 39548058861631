import { getAuthJwt } from '@imaginelearning/web-auth';
import { userService } from '@imaginelearning/web-services';

const AUTH_SOURCE_KEY = 'auth.authSource';

angular
	.module('api.authentication', [
		'config',
		'angular-jwt',
		'utils.cookies',
		'utils.date',
		'utils.unsupported.detector',
		'api',
		'api.token',
		'api.organization',
		'api.urls',
		'api.user',
		'api.application.user',
		'portal.service',
		'ngStorage',
		'api.sso',
		'api.assessment.provider',
		'api.authentication.constants',
		'api.authentication.oidcUserManager',
		'ngStorage',
		'react.dependencies'
	])
	.constant('AuthenticationServiceEvents', {
		userLoggedIn: 'AuthenticationService.userLoggedIn',
		growthReportRolloverLoaded: 'AuthenticationService.growthReportRolloverLoaded'
	})
	.provider('AuthenticationService', authenticationServiceProvider);

function authenticationServiceProvider() {
	var provider = this;

	provider.defaultUrl = '';
	provider.$get = authenticationServiceFactory;

	authenticationServiceFactory.$inject = [
		'$cookieStore',
		'$localStorage',
		'$q',
		'$http',
		'urls',
		'$rootScope',
		'$sessionStorage',
		'$window',
		'ApplicationUserService',
		'AuthenticationServiceEvents',
		'CookieNames',
		'DateUtils',
		'Organization',
		'Token',
		'UnsupportedDetector',
		'User',
		'UserRole',
		'Portal',
		'OidcUserManager',
		'AssessmentProvider',
		'SsoApi',
		'ReactDependenciesService'
	];

	function authenticationServiceFactory(
		$cookieStore,
		$localStorage,
		$q,
		$http,
		urls,
		$rootScope,
		$sessionStorage,
		$window,
		ApplicationUserService,
		AuthenticationServiceEvents,
		CookieNames,
		DateUtils,
		Organization,
		Token,
		UnsupportedDetector,
		User,
		UserRole,
		Portal,
		OidcUserManager,
		AssessmentProvider,
		SsoApi,
		ReactDependenciesService
	) {
		const authenticationService = {
			authenticate,
			isAuthenticated,
			isILAdmin,
			logOff,
			impersonating,
			impersonate,
			impersonateStudent,
			abortImpersonation,
			isJwt,
			startLogin,
			completeLogin
		};
		return authenticationService;

		function completeLogin() {
			return OidcUserManager.signinRedirectCallback()
				.then(oidcUser => {
					const { state: stateData } = oidcUser;
					if (stateData) {
						const { authSource } = stateData;
						if (authSource) {
							localStorage.setItem(AUTH_SOURCE_KEY, atob(authSource));
						}
					}

					// Clear out notifications and cached results
					$rootScope.$broadcast('NotificationFactory.clear', { purge: true });
					$rootScope.$broadcast('utils.timed_cache.clear');

					// Parse user from JWT
					const { access_token } = oidcUser;
					const user = userFromJwt(access_token);

					// Keep out Student and ReadOnlyILSiteAdmin roles. Student will redirect to ULP/PP login while ReadOnlyILSiteAdmin will redirect to Maestro.
					if (user.role === UserRole.student || user.role === UserRole.readOnlyILSiteAdmin) {
						return user;
					} else {
						$localStorage[CookieNames.authentication.lastUser] = user.username;
						if (user.siteCode && user.siteCode === 'demo') {
							var existingOverride = $localStorage[CookieNames.authentication.overrideCurrentDateAs];
							var existingOverrideUser = $localStorage[CookieNames.authentication.overrideCurrentDateUser];
							if (!existingOverride || existingOverrideUser !== user.id) {
								return $http({
									method: 'GET',
									url: urls.reportsApiUrl + 'user/overriddenDate',
									headers: {
										Authorization: 'Bearer ' + user.token
									}
								}).then(function(result) {
									if (result && result.data) {
										var date = result.data && result.data.match(/T/) ? result.data.split('T')[0] : result.data;
										$localStorage[CookieNames.authentication.overrideCurrentDateAs] = DateUtils.parseDate(date);
										$localStorage[CookieNames.authentication.overrideCurrentDateUser] = user.id;
									}
									return getUserDetails(user);
								});
							}
						}
						return getUserDetails(user);
					}
				})
				.then(user => {
					if (user.role === UserRole.student) {
						const info = UnsupportedDetector.getInfo();
						const platform = info?.os?.family;
						const details = {
							SiteCode: user.siteCode,
							Signature: 'abc',
							Role: UserRole.student,
							EncryptedToken: user.token
						};
						return SsoApi.getStudentLaunchUrl(details, platform);
					} else if (user.role === UserRole.readOnlyILSiteAdmin) {
						return urls.maestroUrl;
					} else {
						ApplicationUserService.setUser(user);
						$rootScope.$broadcast(AuthenticationServiceEvents.userLoggedIn, user);
						$rootScope.authenticating = false;
						return getReturnUrl();
					}
				});
		}

		function startLogin(returnUrl) {
			returnUrl && !/signin/i.test(returnUrl) && setReturnUrl(returnUrl);
			return OidcUserManager.signinRedirect();
		}

		function getReturnUrl() {
			const requestedProduct = $localStorage.requestedProduct;
			if (!!requestedProduct) {
				delete $localStorage.requestedProduct;
			}

			const returnUrl = $sessionStorage.returnUrl;
			delete $sessionStorage.returnUrl;
			return !!requestedProduct ? requestedProduct : returnUrl && returnUrl.expires > Date.now() ? returnUrl.url : null;
		}

		function setReturnUrl(url) {
			const expires = Date.now() + 180000; // 3 minutes from now
			$sessionStorage.returnUrl = { url, expires };
		}

		function userFromJwt(token) {
			const data = getAuthJwt(token);
			const user = {
				siteCode: data.siteCodes,
				token: token,
				username: data.userName,
				id: data.id,
				displayName: data.displayName,
				organizationIds: !!data.organizations && data.organizations.length > 1 ? data.organizations.split(',') : [],
				expires: parseInt(data.exp || 0, 10) * 1000,
				role: data.role,
				impersonating: !!data.impersonation && data.impersonation.length > 1,
				impersonation: data.impersonation
					? {
							ilUser: data.impersonation[0],
							user: data.impersonation[1]
					  }
					: null,
				jwt: data.jwt
			};

			return user;
		}

		function userFromLegacyResponse(response) {
			return {
				siteCode: response.sitecodes,
				token: response.access_token,
				username: response.username,
				id: response.id,
				displayName: response.displayName,
				expires: Date.now() + response.expires_in * 1000,
				role: response.role,
				roleType: response.role_type
			};
		}

		function userFromResponse(response) {
			var user,
				token = _.get(response, 'data.access_token');
			if (token && token.match(/^eyJ[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/)) {
				user = userFromJwt(token);
			} else {
				user = userFromLegacyResponse(response.data);
			}
			return user;
		}

		function getUserDetails(authUser) {
			if (!authUser || !authUser.id) {
				return $q.reject({
					message: 'ERROR: Authentication Service, getUserDetails - Invalid User',
					data: authUser
				});
			}

			let user = { ...authUser, userRole: authUser.role };
			const oldUser = ApplicationUserService.getUser();
			if (oldUser && oldUser.id === user.id) {
				user = { ...user, ...oldUser };
			}

			// This needs to be set before we use any API services
			ApplicationUserService.setUser(user);
			let promise, rootOrganization;

			if (authUser.role === UserRole.ilAdmin || authUser.role === UserRole.ilSiteAdmin) {
				const siteCodes = authUser && authUser.siteCode ? authUser.siteCode.split(',') : [];
				const promises = siteCodes.map(siteCode =>
					Organization.query({
						search: siteCode,
						additionalFields: ['parentName', 'streetAddress', 'city', 'state', 'zip']
					}).then(details => {
						if (details && details.items && details.items.length) {
							if (!rootOrganization) {
								rootOrganization = details.items[0];
							}
							return details;
						} else {
							return $q.reject({
								message:
									'ERROR: Authentication Service, getOrganizations - No organization found matching supplied site code: ' +
									siteCode
							});
						}
					})
				);

				promise = $q.all(promises).then(results => {
					const items = (results || []).reduce((aggregator, result) => [...aggregator, ...result.items], []);
					return { items };
				});
			} else {
				const _userService = userService(ReactDependenciesService.apiConfig());
				promise = _userService
					.organizations(user.id, {
						additionalFields: ['parentName', 'products', 'streetAddress', 'city', 'state', 'zip'],
						limit: -1
					})
					.toPromise();
			}

			return promise
				.then(results => {
					user.organizations = results.items || results || [];
					user.organizationIds = user.organizationIds || user.organizations.map(({ id }) => id);
					rootOrganization = user.organizations.find(o => o.id === user.organizationIds[0]);

					// Normal users may not have access to their site
					// code root org so we use their first organization as root

					//TODO: pull hard coded root org into application.service once activity menu branch is merged
					const rootOrg =
						rootOrganization || user.organizations[0] || (user.role === UserRole.ilAdmin ? { id: Portal.appOrgId } : {});
					ApplicationUserService.setRootOrganization(rootOrg);

					if (rootOrg && rootOrg.id && user.role !== UserRole.ilSiteAdmin && user.role !== UserRole.ilAdmin) {
						let products = user.organizations.reduce((prods, org) => [...prods, ...(org.products || [])], []);

						return $q
							.all([
								Organization.get(rootOrg.id, {
									additionalFields: ['products', 'parentName', 'streetAddress', 'city', 'state', 'zip']
								}),
								Organization.children(rootOrg.id, {
									includeDescendants: true,
									additionalFields: ['products' /*, 'settings'*/]
								})
							])
							.then(([org, { items: children } /*, settings*/]) => {
								const orgs = [org, ...(children || [])];
								return _.chain(orgs)
									.reduce((prods, org) => (org ? [...prods, ...org.products] : prods), products)
									.uniq()
									.value();
							});
					}
					return $q.when([]);
				})
				.then(products => {
					if (user.role !== UserRole.ilSiteAdmin && user.role !== UserRole.ilAdmin) {
						user.products = products && products.length ? products : undefined;
						if (!user.products) {
							return $q.reject({
								success: false,
								message: 'ERROR: Authentication Service, getUserDetails - No product associated with user root group found',
								data: {
									error: 'no_product_found'
								}
							});
						}
						return AssessmentProvider.getForOrgAndChildren(user.organizationIds[0]);
					} else {
						user.products = [];
						return $q.when({});
					}
				})
				.then(providers => {
					user.defaultAssessmentProvider = _.get(providers, 'defaultAssessmentProvider');
					user.assessmentProviders = _.get(providers, 'assessmentProviders');
					ApplicationUserService.setAuthenticatedUser(user);
					return user;
				});
		}

		function authenticate(username, password) {
			return Token.token(username, password)
				.then(response => {
					// Clear out notifications and cached results
					$rootScope.$broadcast('NotificationFactory.clear', { purge: true });
					$rootScope.$broadcast('utils.timed_cache.clear');

					const user = userFromResponse(response);

					// Keep students out
					if (user.role === UserRole.student) {
						return $q.reject({
							status: 400,
							data: {
								error: 'invalid_grant'
							}
						});
					}
					$localStorage[CookieNames.authentication.lastUser] = response.data.username;
					return getUserDetails(user);
				})
				.then(user => {
					ApplicationUserService.setUser(user);
					return $q.when({
						success: true,
						message: null
					});
				});
		}

		function isAuthenticated() {
			return ApplicationUserService.isAuthenticated();
		}

		function isILAdmin() {
			var role = ApplicationUserService.getUserRole();
			return role && (role === UserRole.ilAdmin || role === UserRole.ilSiteAdmin);
		}

		function isJwt() {
			return ApplicationUserService.isJwt();
		}

		function logOff() {
			$rootScope.$broadcast('NotificationFactory.clear', { purge: true });
			return OidcUserManager.signoutRedirect();
		}

		function impersonating() {
			return ApplicationUserService.impersonating();
		}

		function abortImpersonation() {
			OidcUserManager.signoutRedirect();
		}

		function impersonate(user, resumeUrl) {
			//if no user is passed, disable impersonation
			if (!user) {
				if (!ApplicationUserService.impersonating()) {
					return $q.reject(new Error('Invalid alias requested'));
				}
				// Calling logOff will pop the current user off the auth stack and end impersonation
				return logOff();
			}

			const token = ApplicationUserService.getToken();
			if (!token) {
				//log error
				return $q.reject(new Error('Invalid alias requested'));
			}

			// instead of relying on localStorage let's determine which auth to use from the existing token issuer
			const decoded = getAuthJwt(token);
			const promise = decoded?.iss?.match(/edgenuity/i)
				? Token.impersonate(token, user.id, resumeUrl)
				: Token.impersonateILAuth(token, user.username, resumeUrl);

			return promise.then(response => {
				if (!response || !response.data || !response.data.access_token) {
					return $q.reject(new Error('Invalid alias requested'));
				}

				const user = userFromJwt(response.data.access_token);
				return getUserDetails(user).then(detailedUser => {
					ApplicationUserService.setUser(detailedUser);
					$rootScope.$broadcast('NotificationFactory.impersonation');
					$cookieStore.put('lastUrl', $window.location.href);
				});
			});
		}

		// Simplified impersonation for students. Do not do any additional call to getUserDetails.
		function impersonateStudent(user, resumeUrl) {
			//if no user is passed, disable impersonation
			if (!user) {
				if (!ApplicationUserService.impersonating()) {
					return $q.reject(new Error('Invalid alias requested'));
				}
				// Calling logOff will pop the current user off the auth stack and end impersonation
				return logOff();
			}

			const token = ApplicationUserService.getToken();
			if (!token) {
				//log error
				return $q.reject(new Error('Invalid alias requested'));
			}
			// instead of relying on localStorage let's determine which auth to use from the existing token issuer
			const decoded = getAuthJwt(token);
			const promise = Token.impersonate(token, user.id, resumeUrl);

			return promise.then(response => {
				if (!response || !response.data || !response.data.access_token) {
					return $q.reject(new Error('Invalid alias requested'));
				}
				const jwtUser = userFromJwt(response.data.access_token);
				let user = { ...jwtUser, userRole: jwtUser.role };
				// This needs to be set before we navigate to the Product Portal.
				ApplicationUserService.setUser(user);
				$rootScope.$broadcast('NotificationFactory.impersonation');
				$cookieStore.put('lastUrl', $window.location.href);
			});
		}
	}
}
