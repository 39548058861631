(function() {
	angular.module('reporting.api.helpers', []).service('ReportingAPIHelpers', service);

	service.$inject = [];

	function service() {
		this.transformResponseBenchmark = transformResponseBenchmark;
		this.transformTextResponse = transformTextResponse;
		this.transformEspanolUsageOrganizationListResponseToCountAndItems = transformEspanolUsageOrganizationListResponseToCountAndItems;
		this.transformEspanolUsageGroupListResponseToCountAndItems = transformEspanolUsageGroupListResponseToCountAndItems;
		this.transformEspanolUsageStudentListResponseToCountAndItems = transformEspanolUsageStudentListResponseToCountAndItems;

		function transformResponseBenchmark(data) {
			var items, json, benchmarkTestScoresTerms, languageGradeLevelPerformanceTerms, literacyGradeLevelPerformanceTerms;
			try {
				json = angular.fromJson(data);
				items = _.map(json.results, function(item) {
					if (item.literacyGradeLevelPerformance && item.literacyGradeLevelPerformance !== null) {
						literacyGradeLevelPerformanceTerms = {
							beginning: _.find(item.literacyGradeLevelPerformance.testTerms, function(term) {
								return _.toLower(term.testTerm) === 'beginning';
							}),
							middle: _.find(item.literacyGradeLevelPerformance.testTerms, function(term) {
								return _.toLower(term.testTerm) === 'middle';
							}),
							end: _.find(item.literacyGradeLevelPerformance.testTerms, function(term) {
								return _.toLower(term.testTerm) === 'end';
							})
						};
						item.literacyGradeLevelPerformance = literacyGradeLevelPerformanceTerms;
					}

					if (item.languageGradeLevelPerformance && item.languageGradeLevelPerformance !== null) {
						languageGradeLevelPerformanceTerms = {
							beginning: _.find(item.languageGradeLevelPerformance.testTerms, function(term) {
								return _.toLower(term.testTerm) === 'beginning';
							}),
							middle: _.find(item.languageGradeLevelPerformance.testTerms, function(term) {
								return _.toLower(term.testTerm) === 'middle';
							}),
							end: _.find(item.languageGradeLevelPerformance.testTerms, function(term) {
								return _.toLower(term.testTerm) === 'end';
							})
						};
						item.languageGradeLevelPerformance = languageGradeLevelPerformanceTerms;
					}

					if (item.benchmarkTestScores && item.benchmarkTestScores !== null) {
						benchmarkTestScoresTerms = {
							languageGrowthYtd: item.benchmarkTestScores.languageGrowthYtd,
							languageYtdChange: item.benchmarkTestScores.languageYtdChange,
							literacyGrowthYtd: item.benchmarkTestScores.literacyGrowthYtd,
							literacyYtdChange: item.benchmarkTestScores.literacyYtdChange,
							beginning: _.find(item.benchmarkTestScores.testTerms, function(term) {
								return _.toLower(term.name) === 'beginning';
							}),
							middle: _.find(item.benchmarkTestScores.testTerms, function(term) {
								return _.toLower(term.name) === 'middle';
							}),
							end: _.find(item.benchmarkTestScores.testTerms, function(term) {
								return _.toLower(term.name) === 'end';
							})
						};
						item.benchmarkTestScores = benchmarkTestScoresTerms;
					}

					return item;
				});
			} catch (e) {
				return data;
			}

			return {
				totalCount: json.totalCount,
				items: items
			};
		}

		function transformTextResponse(data, headersGetter) {
			return {
				data: data || ''
			};
		}

		function transformEspanolUsageOrganizationListResponseToCountAndItems(data, headersGetter) {
			var response = JSON.parse(data);
			var organizations = (response && response.organizations) || [];
			organizations = _.map(organizations, function(organization) {
				var data = {
					usage: {
						rangeUsage: organization.usage || { averageSeconds: 0 }
					},
					orgData: organization.orgData
				};
				data.usage.rangeUsage.averageSeconds = _.get(data, 'usage.rangeUsage.averageUsage', 0);
				return data;
			});

			return {
				totalCount: +headersGetter('x-total-count') || organizations.length || 0,
				items: organizations
			};
		}

		function transformEspanolUsageGroupListResponseToCountAndItems(data, headersGetter) {
			var response = JSON.parse(data);
			var groups = response.groups || [];
			groups = _.map(groups, function(group) {
				var data = {
					usage: {
						rangeUsage: group.usage || { averageSeconds: 0 }
					},
					groupData: group.groupData
				};
				data.usage.rangeUsage.averageSeconds = _.get(data, 'usage.rangeUsage.averageUsage', 0);
				return data;
			});

			return {
				totalCount: +headersGetter('x-total-count') || groups.length || 0,
				items: groups
			};
		}

		function transformEspanolUsageStudentListResponseToCountAndItems(data, headersGetter) {
			var response = JSON.parse(data);
			var students = response.students || [];
			students = _.map(students, function(student) {
				var data = {
					usage: student.usage,
					studentData: student.studentData
				};
				return data;
			});

			return {
				totalCount: +headersGetter('x-total-count') || students.length || 0,
				items: students
			};
		}
	}
})();
