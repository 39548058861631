(function(angular, undefined) {
	angular.module('api.user', ['api.urls', 'api.helpers', 'utils.timed_cache', 'ngResource']).factory('User', userFactory);

	userFactory.$inject = ['$http', '$q', '$resource', 'APIHelpers', 'TimedCache', 'urls'];

	function userFactory($http, $q, $resource, APIHelpers, TimedCache, urls) {
		var resourceUrl = 'users/:userId';
		var url = urls.managementApiUrl + resourceUrl;
		var setupUrl = urls.managementApiUrl + 'passwordReset/multi';
		var userIds = [];

		var actions = {
			ancestors: { method: 'GET', url: url + '/ancestors', isArray: true },

			paged: { method: 'GET', transformResponse: APIHelpers.convertToCountAndItems },

			list: {
				method: 'GET',
				transformResponse: APIHelpers.convertToCountAndItems
			},

			quickList: { method: 'GET', isArray: true },

			query: { method: 'GET', transformResponse: APIHelpers.convertToCountAndItems },

			get: { method: 'GET', params: { userId: '@userId' }, url: url },

			update: { method: 'PATCH', params: { userId: '@id' } },

			// Update only specified fields
			patch: { method: 'PATCH', params: { userId: '@id' } },

			remove: { method: 'DELETE' },

			// A list of organizations that the user id belongs to
			organizations: { method: 'GET', url: url + '/organizations', transformResponse: APIHelpers.convertToCountAndItems },

			// A list of classes that user id belongs to
			classes: { method: 'GET', url: url + '/groups', isArray: true },

			// A list of classes that user id belongs to
			groups: { method: 'GET', url: url + '/groups', transformResponse: APIHelpers.convertToCountAndItems },

			//A list of students within user's scope of influence (the ones he can read)
			students: { method: 'GET', url: url + '/students', transformResponse: APIHelpers.convertToCountAndItems },

			//A list of students within user's scope of influence (the ones he can read)
			assignedStudents: { method: 'GET', url: url + '/assignedStudents', transformResponse: APIHelpers.convertToCountAndItems },

			usersById: {
				method: 'GET',
				transformResponse: APIHelpers.convertToCountAndItems
			},

			setGroups: {
				method: 'PUT',
				url: url + '/groups',
				params: { userId: '@userId' }
			},

			setOrgs: {
				method: 'PUT',
				url: url + '/organizations',
				params: { userId: '@userId' }
			},

			sendSetupEmails: {
				method: 'POST',
				url: setupUrl
			}
		};

		var resource = $resource(url, null, TimedCache.enableCaching(actions));

		return {
			ancestors: function(userId, organizationId) {
				var params = {
					userId: userId,
					orgId: organizationId
				};
				return resource.ancestors(params).$promise;
			},
			paged: function(params, noSort) {
				params = params || {};
				if (!noSort && (!params.sortby || !params.sortby.length)) {
					params.sortby = 'lastName';
				}

				return resource.paged(params).$promise;
			},
			query: function(params) {
				return resource.query(params).$promise;
			},
			list: function(params) {
				return resource.list(params).$promise;
			},
			get: function(userId, params) {
				params = params || {};
				params.userId = userId;
				return resource.get(params).$promise;
			},
			update: function(updatedUser) {
				return resource.update(updatedUser).$promise;
			},
			patch: function(updatedUser) {
				return resource.patch(updatedUser).$promise;
			},
			remove: function(userId) {
				return resource.remove({ userId: userId }).$promise;
			},
			organizations: function(userId, params) {
				params = params || {};
				params.userId = userId;
				return resource.organizations(params).$promise;
			},
			classes: function(userId, params) {
				params = params || {};
				params.userId = userId;
				return resource.classes(params).$promise;
			},
			groups: function(userId, params) {
				params = params || {};
				params.userId = userId;
				return resource.groups(params).$promise;
			},
			students: function(userId, params) {
				params = params || {};
				params.userId = userId;
				return resource.students(params).$promise;
			},
			assignedStudents: function(userId, params) {
				params = params || {};
				params.userId = userId;
				params.sortby = params.sortby || 'lastName,firstName';
				return resource.assignedStudents(params).$promise;
			},
			save: function(newUser) {
				return resource.save(newUser).$promise;
			},
			studentsCount: function(userId) {
				return $http.head(urls.managementApiUrl + 'users/' + userId + '/students').then(function(response) {
					return response.headers('X-Total-Count') || 0;
				});
			},
			assignedStudentsCount: function(userId) {
				var deferred = $q.defer();
				$http
					.head(urls.managementApiUrl + 'users/' + userId + '/assignedStudents')
					.success(function(data, status, headers) {
						deferred.resolve(headers('X-Total-Count') || 0);
					})
					['catch'](function(response) {
						deferred.reject(response);
					});

				return deferred.promise;
			},
			usersById: function(ids, params) {
				if (ids?.length < 1) {
					return $q.when({ totalCount: 0, items: [] });
				}
				const queryParams = {
					...params,
					ids,
					limit: params?.limit > 0 ? params.limit : ids.length
				};
				return resource.usersById(queryParams).$promise;
			},
			setGroups: function(userId, groups) {
				return resource.setGroups({ userId: userId }, angular.toJson(groups)).$promise;
			},
			setOrgs: function(userId, orgs) {
				return resource.setOrgs({ userId: userId }, angular.toJson(orgs)).$promise;
			},

			quickList: function(search, params = {}) {
				const queryParams = {
					...params,
					search,
					limit: params?.limit > 0 ? params.limit : -1
				};
				return resource.quickList(queryParams).$promise;
			},

			organizationsPaged: function(userId, params) {
				params.userId = userId;
				return resource.organizations(params).$promise;
			},
			groupsPaged: function(userId, params) {
				params.userId = userId;
				return resource.groups(params).$promise;
			},
			studentsPaged: function(userId, params) {
				params.userId = userId;
				return resource.students(params).$promise;
			},
			sendSetupEmails: function(userNames) {
				var payload = {
					usernames: userNames,
					isSetupRequest: true
				};
				return resource.sendSetupEmails(payload).$promise;
			}
		};
	}
})(window.angular);
