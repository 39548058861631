(function() {
	'use strict';

	angular.module('api.logger').config(config);

	config.$inject = ['$provide'];
	function config($provide) {
		var functionNames = ['debug', 'info', 'warn', 'error', 'log'],
			functions = {},
			tests = {
				debug: /debug/i,
				info: /debug|info/i,
				warn: /debug|info|warn/i,
				error: /debug|info|warn|error/i,
				log: /debug|info|warn|error/i
			};

		$provide.decorator('$log', logDecorator);

		////////////////

		logDecorator.$inject = ['$delegate', '$window', 'LogConfig', 'stackTrace'];
		function logDecorator($delegate, $window, LogConfig, stackTrace) {
			angular.forEach(functionNames, function(fn) {
				// Save original function
				functions[fn] = $delegate[fn];

				// Replace with new logging function
				$delegate[fn] = getLogFunction(fn);
			});

			return $delegate;

			////////////////

			function getLogFunction(fn) {
				// This relies on the `CustomEvent` interface being available.
				// For browsers that don't support it, just use the old function.
				if (!$window.CustomEvent) {
					return functions[fn];
				}

				return function() {
					var args = [].slice.call(arguments);

					// Only log to API if at or above LogConfig.remoteLevel
					if (tests[fn].test(LogConfig.remoteLevel())) {
						var stack = stackTrace
							.get()
							.slice(1) // Remove this function call from the stack
							.join('\n');

						// Angular's event system isn't available to us because we can't access
						// $rootScope without creating a circular dependency. We'll use the browser's
						// built-in event system instead.
						var event = new $window.CustomEvent('Logger.Log', {
							detail: {
								severity: fn,
								log: args,
								stack: stack
							}
						});
						$window.dispatchEvent(event);
					}

					// Call the original function
					functions[fn].apply($delegate, args);
				};
			}
		}
	}
})();
