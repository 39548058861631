(function(angular, undefined) {
	angular.module('api.iladmin', ['api.urls', 'api.helpers', 'utils.timed_cache', 'ngResource']).factory('ILAdmin', ilAdminFactory);

	ilAdminFactory.$inject = ['$resource', 'urls', 'APIHelpers', 'TimedCache'];

	function ilAdminFactory($resource, urls, APIHelpers, TimedCache) {
		var resourceUrl = 'iladmins/:userId';
		var url = urls.managementApiUrl + resourceUrl;

		var actions = {
			get: {
				url: url,
				method: 'GET',
				params: {
					userId: '@userId'
				}
			},

			patch: {
				url: url,
				method: 'PATCH',
				params: {
					userId: '@id'
				}
			},

			delete: {
				url: url,
				method: 'DELETE'
			},

			query: {
				url: url,
				method: 'GET',
				transformResponse: APIHelpers.convertToCountAndItems
			},

			organizations: {
				url: url + '/organizations',
				method: 'GET',
				transformResponse: APIHelpers.convertToCountAndItems
			}
		};

		var resource = $resource(url, null, TimedCache.enableCaching(actions));

		return {
			get: function(userId) {
				return resource.get({ userId: userId }).$promise;
			},
			patch: function(updatedUser) {
				return resource.patch(updatedUser).$promise;
			},
			delete: function(userId) {
				return resource.remove({ userId: userId }).$promise;
			},
			query: function(params) {
				return resource.query(params).$promise;
			},
			organizations: function(userId, params) {
				params = params || {};
				params.userId = userId;
				return resource.organizations(params).$promise;
			}
		};
	}
})(window.angular);
