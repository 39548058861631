(function() {
	angular.module('api.reporting', [
		'api.reports.filter',
		'api.reports.growth',
		'api.reports.progress',
		'api.reports.skills',
		'api.reports.benchmarks',
		'api.usage',

		'report.queue',
		'report.printing',
		'reporting.api'
	]);
})();
