(function() {
	angular
		.module('api.manager.constants', [])
		.constant('Categories', {
			organizations: 'organizations',
			groups: 'groups',
			staff: 'staff',
			students: 'students',
			artifacts: 'artifacts'
		})
		.constant('Types', {
			organization: 'organization',
			group: 'group',
			staff: 'staff',
			student: 'student',
			artifact: 'artifact'
		})
		.constant('isEntitySynced', function(entity) {
			return !!(entity.syncId && entity.syncId.length && entity.syncId.indexOf('migration') === -1);
		})
		.constant('getNameList', function(entityList) {
			if (!entityList || !entityList.length) {
				return '';
			}

			var tmp = [];
			angular.forEach(entityList, function(entity) {
				tmp.push(entity.name);
			});
			return tmp.join(', ');
		})
		.constant('getDisplayText', function(entity) {
			var displayText = '';
			if (entity && entity.lastName && entity.firstName) {
				displayText = entity.lastName + ', ' + entity.firstName;
			}
			if (entity && entity.name) {
				displayText = entity.name;
			}
			return displayText;
		});
})();
