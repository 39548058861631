angular.module('api.reassignAssessment', ['api.urls', 'ngResource']).factory('ReassignAssessmentService', factory);

factory.$inject = ['$resource', 'urls'];

function factory($resource, urls) {
	const resourceUrl = 'teacher/:resetType/:productName/:studentId';
	const url = urls.unVersionedSequencerApiUrl + resourceUrl;
	const actions = {
		reset: {
			method: 'POST',
			params: { resetType: '@resetType', productName: '@productName', studentId: '@studentId' }
		}
	};

	let resource = $resource(url, null, actions);

	return {
		resetBenchmark: function(productName, studentId) {
			return resource.reset({ resetType: 'resetTest', productName, studentId }).$promise;
		},
		resetPlacement: function(productName, studentId) {
			return resource.reset({ resetType: 'resetUser', productName, studentId }).$promise;
		}
	};
}
