(function() {
	'use strict';

	angular
		.module('api.application.user', [])
		.constant('UserRole', {
			anonymous: 'Anonymous',
			student: 'Student',
			teacher: 'Teacher',
			parent: 'Parent',
			admin: 'Administrator',
			ilAdmin: 'ILAdmin',
			ilSiteAdmin: 'ILSiteAdmin',
			readOnlyILSiteAdmin: 'ReadOnlyILSiteAdmin'
		})
		.service('ApplicationUserService', ApplicationUserService);

	ApplicationUserService.$inject = ['UserRole', '$q'];

	function ApplicationUserService(UserRole, $q) {
		let _user;
		let _authenticatedUser;
		let _organizations;
		let _rootOrganization;

		this.waitForUser = waitForUser;
		this.setUser = setUser;
		this.setOrganizations = setOrganizations;
		this.setRootOrganization = setRootOrganization;
		this.getUser = getUser;
		this.setAuthenticatedUser = setAuthenticatedUser;
		this.getAuthenticatedUser = getAuthenticatedUser;
		this.getImpersonator = getImpersonator;
		this.getUserRole = getUserRole;
		this.getRootOrganization = getRootOrganization;
		this.getOrganizations = getOrganizations;
		this.isAdmin = isAdmin;
		this.isIlAdmin = isIlAdmin;
		this.isDistrictAdmin = isDistrictAdmin;
		this.isTeacher = isTeacher;
		this.isParent = isParent;
		this.isAuthenticated = isAuthenticated;
		this.impersonating = impersonating;
		this.getToken = getToken;
		this.getJwt = getJwt;
		this.remove = remove;
		this.isJwt = isJwt;

		function waitForUser() {
			let counter = 0;
			let deferred = $q.defer();
			const interval = setInterval(() => {
				if (!!_user && !!_user.id) {
					clearInterval(interval);
					deferred.resolve(_user);
				} else {
					if (counter >= 4000) {
						clearInterval(interval);
						deferred.reject(Error('No authenticated user found.'));
					}
					counter++;
				}
			}, 100);
			return deferred.promise;
		}

		function setUser(user) {
			if (!!user && !user.expires) {
				user.expires = Date.now() + 3600000; // One hour from current time
			}
			_user = { ...user };
		}

		function setOrganizations(organizations) {
			_organizations = organizations;
		}

		function setRootOrganization(rootOrganization) {
			_rootOrganization = rootOrganization;
		}

		function getUser() {
			return _user;
		}

		function setAuthenticatedUser(user) {
			this.setUser(user);
			_authenticatedUser = _user;
		}

		function getAuthenticatedUser() {
			return _authenticatedUser;
		}

		function getImpersonator() {
			return _user && _user.impersonating ? _.get(_user, 'impersonation.ilUser.name') : undefined;
		}

		function getUserRole() {
			return _user && _user.userRole ? _user.userRole : UserRole.anonymous;
		}

		function getRootOrganization() {
			return _rootOrganization;
		}

		function getOrganizations() {
			return _user && _user.organizations;
		}

		function isAdmin() {
			return _user && (_user.role === UserRole.admin || _user.role === UserRole.ilAdmin || _user.role === UserRole.ilSiteAdmin);
		}

		function isIlAdmin() {
			return _user && (_user.role === UserRole.ilAdmin || _user.role === UserRole.ilSiteAdmin);
		}

		function isDistrictAdmin() {
			return (
				isAdmin() &&
				!!(
					_user.organizations &&
					_user.organizations.length &&
					_user.organizations.some(org => org.organizationType === 'District')
				)
			);
		}

		function isTeacher() {
			return _user && _user.role === UserRole.teacher;
		}

		function isParent() {
			return _user && _user.role === UserRole.parent;
		}

		function isAuthenticated() {
			return !!(_user && _user.token && _user.expires && _user.expires > Date.now());
		}

		function isJwt() {
			return _user.isJwt ? _user.isJwt : false;
		}

		function impersonating() {
			return !!_user && !!_user.impersonating;
		}

		function getToken() {
			return _user ? _user.token || undefined : undefined;
		}

		function getJwt() {
			return _user ? _user.jwt || undefined : undefined;
		}

		function remove() {
			_rootOrganization = undefined;
			_user = undefined;
		}
	}
})();
