angular
	.module('api.featureFlags', ['api.urls', 'api.application.user', 'ngResource'])
	.factory('FeatureFlagsService', featureFlagsFactory)
	.constant('featureFlagStates', {
		hide: 'hide',
		show: 'show'
	});

featureFlagsFactory.$inject = ['$resource', 'urls', 'ApplicationUserService', 'featureFlagStates'];

function featureFlagsFactory($resource, urls, ApplicationUserService, featureFlagStates) {
	const resourceUrl = '/evaluation';
	const url = urls.featureFlagsApiUrl + resourceUrl;
	const actions = {
		evaluate: {
			method: 'POST'
		}
	};

	let resource = $resource(url, null, actions);

	function getDataFromAuth() {
		let user = ApplicationUserService.getUser() || {};
		let organization = ApplicationUserService.getRootOrganization() || {};
		return {
			userId: user.id || '',
			userOrganizationId: (organization && organization.id) || '',
			userType: user.role || '',
			userSiteCode: user.siteCode || '',
			userName: user.username || ''
		};
	}

	return {
		evaluate: function(flagKey, appContext) {
			let userContext = getDataFromAuth();
			return resource
				.evaluate({
					entityID: userContext.userId,
					flagKey,
					entityContext: {
						...userContext,
						...appContext
					},
					enableDebug: urls.environment === 'development'
				})
				.$promise.then(result =>
					result && result.variantKey && result.variantKey === featureFlagStates.show
						? featureFlagStates.show
						: featureFlagStates.hide
				);
		},
		evaluateKey: function(flagKey, appContext) {
			let userContext = getDataFromAuth();
			return resource
				.evaluate({
					entityID: userContext.userId,
					flagKey,
					entityContext: {
						...userContext,
						...appContext
					},
					enableDebug: urls.environment === 'development'
				})
				.$promise.then(result => result?.variantKey);
		}
	};
}
