(function(angular, undefined) {
	angular
		.module('api.import.schedule', ['api.urls', 'api.helpers', 'utils.timed_cache', 'ngResource'])
		.factory('ImportSchedule', importsScheduleFactory);

	importsScheduleFactory.$inject = ['$http', '$q', '$resource', 'APIHelpers', 'urls', 'TimedCache'];

	function importsScheduleFactory($http, $q, $resource, APIHelpers, urls, TimedCache) {
		var url = urls.importApiUrl + 'schedule/:siteCode';

		var actions = {
			//package
			getSchedulePackage: { method: 'GET', url: url },

			createSchedulePackage: { method: 'POST', url: url },

			//detail
			getScheduleDetail: { method: 'GET', url: url + '/job' },

			createScheduleDetail: { method: 'POST', url: url + '/job' },

			updateScheduleDetail: { method: 'PUT', url: url + '/job' },

			deleteScheduleDetail: { method: 'DELETE', url: url + '/job' },

			//trigger
			getScheduleTrigger: { method: 'GET', url: url + '/trigger/:triggerId' },

			createScheduleTrigger: { method: 'POST', url: url + '/trigger/' },

			updateScheduleTrigger: { method: 'PUT', url: url + '/trigger/' },

			deleteScheduleTrigger: { method: 'DELETE', url: url + '/trigger/' }
		};

		var cache = TimedCache.create(15 * 1000);
		var resource = $resource(url, null, TimedCache.enableCaching(actions, cache));

		return {
			//package
			getSchedulePackage: function(siteCode) {
				var params = {};
				params.siteCode = siteCode;
				return resource.getSchedulePackage(params).$promise;
			},
			createSchedulePackage: function(siteCode, schedulePackage) {
				var params = {};
				params.siteCode = siteCode;
				return resource.createSchedulePackage(params, schedulePackage).$promise;
			},

			//detail
			getScheduleDetail: function(siteCode) {
				var params = {};
				params.siteCode = siteCode;
				return resource.getScheduleDetail(params).$promise;
			},
			createScheduleDetail: function(siteCode, schedulePackage) {
				var params = {};
				params.siteCode = siteCode;
				return resource.createScheduleDetail(params, schedulePackage).$promise;
			},
			updateScheduleDetail: function(siteCode, scheduleDetail) {
				var params = {};
				params.siteCode = siteCode;
				return resource.updateScheduleDetail(params, scheduleDetail).$promise;
			},
			deleteScheduleDetail: function(siteCode) {
				var params = {};
				params.siteCode = siteCode;
				return resource.deleteScheduleDetail(params).$promise;
			},

			//trigger
			getScheduleTrigger: function(siteCode, triggerId) {
				var params = {};
				params.siteCode = siteCode;
				params.triggerId = triggerId;
				return resource.getScheduleTrigger(params).$promise;
			},
			createScheduleTrigger: function(siteCode, scheduleTrigger) {
				var params = {};
				params.siteCode = siteCode;
				return resource.createScheduleTrigger(params, scheduleTrigger).$promise;
			},
			updateScheduleTrigger: function(siteCode, scheduleTrigger) {
				var params = {};
				params.siteCode = siteCode;
				return resource.updateScheduleTrigger(params, scheduleTrigger).$promise;
			},
			deleteScheduleTrigger: function(siteCode) {
				var params = {};
				params.siteCode = siteCode;
				return resource.deleteScheduleTrigger(params).$promise;
			}
		};
	}
})(window.angular);
