(function() {
	'use strict';

	angular.module('api.logger').service('stackTrace', StackTraceService);

	StackTraceService.$inject = [];
	function StackTraceService() {
		this.get = get;

		////////////////

		function get() {
			// Create `Error` object to get stack of current context
			return (new Error().stack || '')
				.split('\n')
				.map(function(sf) {
					return _.trim(sf);
				})
				.slice(2); // Remove top 2 entries, which are the message and this function call
		}
	}
})();
