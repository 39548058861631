(function() {
	angular.module('portal.service', ['product.names']).service('Portal', service);

	service.$inject = ['ApiProducts'];

	function service(ApiProducts) {
		var svc = this;
		svc.appOrgId = '00000000-0000-0000-0000-000000000004';
		svc.noQueryIds = [
			'00000000-0000-0000-0000-000000000000',
			'00000000-0000-0000-0000-000000000003',
			'00000000-0000-0000-0000-000000000004'
		];

		svc.productList = ApiProducts;
		svc.product = ApiProducts.ILE; // default to ILE, update to user default in app controller
	}
})();
