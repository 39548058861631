// this module overrides the default $cacheFactory
angular
	.module('api.cacheFactory', [])
	.provider('$resourcesCache', function ResourceCacheProvider() {
		this.$get = [
			'$cacheFactory',
			function($cacheFactory) {
				return $cacheFactory('resources');
			}
		];
	})
	.provider('$resources_espCache', function ResourceCacheProvider() {
		this.$get = [
			'$cacheFactory',
			function($cacheFactory) {
				return $cacheFactory('resources_esp');
			}
		];
	})
	.provider('$helpCache', function ResourceCacheProvider() {
		this.$get = [
			'$cacheFactory',
			function($cacheFactory) {
				return $cacheFactory('help');
			}
		];
	})
	.factory('$cacheFactory', [
		'$rootScope',
		'$sce',
		function($rootScope, $sce) {
			var caches = {};

			function cacheFactory(cacheId, options) {
				if (cacheId in caches) {
					throw minErr('$cacheFactory')('iid', "CacheId '{0}' is already taken!", cacheId);
				}

				var size = 0,
					stats = angular.extend({}, options, { id: cacheId }),
					data = {},
					capacity = (options && options.capacity) || Number.MAX_VALUE,
					lruHash = {},
					freshEnd = null,
					staleEnd = null;

				return (caches[cacheId] = {
					put: function(key, value) {
						if (capacity < Number.MAX_VALUE) {
							var lruEntry = lruHash[key] || (lruHash[key] = { key: key });

							refresh(lruEntry);
						}

						if (angular.isUndefined(value)) {
							return;
						}

						if (!(key in data)) {
							size++;
						}
						data[key] = value;

						if (size > capacity) {
							this.remove(staleEnd.key);
						}

						return value;
					},

					get: function(key) {
						if (capacity < Number.MAX_VALUE) {
							var lruEntry = lruHash[key];

							if (!lruEntry) {
								return;
							}
							refresh(lruEntry);
						}

						return data[key];
					},

					remove: function(key) {
						if (capacity < Number.MAX_VALUE) {
							var lruEntry = lruHash[key];

							if (!lruEntry) {
								return;
							}

							if (lruEntry === freshEnd) {
								freshEnd = lruEntry.p;
							}

							if (lruEntry === staleEnd) {
								staleEnd = lruEntry.n;
							}

							link(lruEntry.n, lruEntry.p);

							delete lruHash[key];
						}

						delete data[key];
						size--;
					},

					removeAll: function() {
						data = {};
						size = 0;
						lruHash = {};
						freshEnd = staleEnd = null;
					},

					destroy: function() {
						data = null;
						stats = null;
						lruHash = null;
						delete caches[cacheId];
					},

					info: function() {
						return angular.extend({}, stats, { size: size });
					},

					getTemplates: function() {
						var templates = [];
						for (var property in data) {
							if (data.hasOwnProperty(property)) {
								templates.push({ Id: property, Template: data[property] });
							}
						}
						return templates;
					},

					getTemplatesSanitized: function(excludedTemplatesRegex) {
						var templates = [];
						//strip out html tags
						var regex = /(<([^>]+)>)/gim;
						//strip html leaving inner text
						var stripHTML = /<(?:.|\n)*?>/gim;
						for (var property in data) {
							if (excludedTemplatesRegex && excludedTemplatesRegex.test(property)) {
								continue;
							}
							if (data.hasOwnProperty(property)) {
								var title, cleaned;
								var content = data[property].toString();
								let titleStart = content.indexOf('<h1');
								if (titleStart < 0) {
									titleStart = 0;
								}
								const titleEnd = content.indexOf('</h1>', titleStart);
								title = titleEnd > titleStart ? content.substring(titleStart, titleEnd) : '';
								stripHTML.lastIndex = 0;
								title = _.trim(title.replace(stripHTML, ''));

								content = content.substring(titleEnd + 6);
								cleaned = content.replace(regex, '').toString();
								var newContent = cleaned.split(' ');
								if (newContent.length && newContent.length < 50) {
									newContent.length = 50;
								}
								cleaned = newContent.join(' ');

								if (!title || !title.length) {
									title = cleaned.length < 60 ? cleaned.substring(0, cleaned.length) : cleaned.substring(0, 60);
								}
								templates.push({ Id: property.toString(), Template: cleaned, Title: title });
							}
						}

						return templates;
					}
				});

				function refresh(entry) {
					if (entry !== freshEnd) {
						if (!staleEnd) {
							staleEnd = entry;
						} else if (staleEnd === entry) {
							staleEnd = entry.n;
						}

						link(entry.n, entry.p);
						link(entry, freshEnd);
						freshEnd = entry;
						freshEnd.n = null;
					}
				}

				function link(nextEntry, prevEntry) {
					if (nextEntry !== prevEntry) {
						if (nextEntry) {
							nextEntry.p = prevEntry; //p stands for previous, 'prev' didn't minify
						}
						if (prevEntry) {
							prevEntry.n = nextEntry; //n stands for next, 'next' didn't minify
						}
					}
				}
			}

			cacheFactory.info = function() {
				var info = {};
				forEach(caches, function(cache, cacheId) {
					info[cacheId] = cache.info();
				});
				return info;
			};

			cacheFactory.get = function(cacheId) {
				return caches[cacheId];
			};

			return cacheFactory;
		}
	]);
