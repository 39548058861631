//these should have started as a factory...

(function() {
	angular
		.module('api.manager.helpers', [])
		.constant('managerAPIEntityTypes', {
			organization: 'organization',
			group: 'group',
			staff: 'staff',
			student: 'student',
			artifact: 'artifact',
			unknown: 'unknown'
		})
		.factory('managerAPIHelpers', factory);

	factory.$inject = ['managerAPIEntityTypes'];

	function factory(managerAPIEntityTypes) {
		var managerAPIHelpers = {};
		managerAPIHelpers.getEntityType = getEntityType;
		managerAPIHelpers.isEntitySynced = isEntitySynced;
		managerAPIHelpers.getNameList = getNameList;
		managerAPIHelpers.getDisplayText = getDisplayText;
		managerAPIHelpers.getDetailedDisplayText = getDetailedDisplayText;
		managerAPIHelpers.formatDisplayText = formatDisplayText;
		managerAPIHelpers.getUID = getUID;

		function getUID() {
			//GUID-esque ids
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000)
					.toString(16)
					.substring(1);
			}
			return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
		}

		function getEntityType(entity) {
			if (!entity || entity === null) {
				return managerAPIEntityTypes.unknown;
			}

			if (entity.organizationType) {
				return managerAPIEntityTypes.organization;
			}

			if (entity.groupType) {
				return managerAPIEntityTypes.group;
			}

			if (entity.userType) {
				return managerAPIEntityTypes.staff;
			}

			if (entity.gradeLevel) {
				return managerAPIEntityTypes.student;
			}

			return managerAPIEntityTypes.unknown;
		}

		function isEntitySynced(entity) {
			return !!(entity.syncId && entity.syncId.length && entity.syncId.indexOf('migration') === -1);
		}

		function getNameList(entityList) {
			if (!entityList || !entityList.length) {
				return '';
			}

			var tmp = [];
			angular.forEach(entityList, function(entity) {
				tmp.push(entity.name);
			});
			return tmp.join(', ');
		}

		function getDisplayText(entity) {
			var displayText = '';
			if (entity && entity.lastName && entity.firstName) {
				displayText = entity.lastName + ', ' + entity.firstName;
			}
			if (entity && entity.name) {
				displayText = entity.name;
			}
			return displayText;
		}

		function getDetailedDisplayText(entity) {
			var displayText = '',
				entityType = getEntityType(entity);

			switch (entityType) {
				case managerAPIEntityTypes.student:
					displayText = getDisplayText(entity);
					displayText += ' | ' + entity.gradeLevel;
					break;

				case managerAPIEntityTypes.staff:
					displayText = getDisplayText(entity);
					displayText += ' | ' + entity.userType;
					break;

				case managerAPIEntityTypes.organization:
					displayText = getDisplayText(entity);
					displayText += ' | ' + entity.organizationType;
					break;

				case managerAPIEntityTypes.group:
					displayText = getDisplayText(entity);
					displayText += ' | ' + (entity.studentCount || 0) + ' Students';
					break;
			}
			return displayText;
		}

		/*
		* formatDisplayText: Allows custom string formatting using entity properties
		*
		* @entity: object to use in string formatting
		* @formattedString: string containing any combination of text and object property names
		*
		* ex:
			 entity: { lastName: 'Doe', firstName: 'John', userType: 'Teacher', id: '123456789'}
			 formattedString = '^lastName^, ^firstName^:^id^ | ^userType^';
			 return value: 'Doe, John:123456789 | Teacher'
		*
		* */
		function formatDisplayText(entity, formattedString) {
			var propertyNames,
				expressionString = /\s*((?:\w(?!\s+\^)+|\s(?!\s*\^))+\w)\s*/gim,
				propertyNamesExpression = new RegExp(expressionString);

			if (!entity || entity === null) {
				return '';
			}

			propertyNames = formattedString.match(propertyNamesExpression);
			propertyNames.forEach(function(propertyName) {
				var term = '^' + propertyName + '^';
				formattedString = formattedString.replace(term, entity[propertyName]);
			});

			return formattedString;
		}

		return managerAPIHelpers;
	}
})();
