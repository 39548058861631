(function() {
	angular.module('reporting.partnership', ['api.urls', 'api.application.user', 'ngResource']).factory('ReportingPartnership', factory);

	factory.$inject = ['$resource', 'urls', 'ApplicationUserService'];

	function factory($resource, urls) {
		var url = urls.apiUrl + 'partnershipreport';

		var actions = {
			// generate
			generate: {
				method: 'POST',
				url: url + '/generate'
			}
		};

		var resource = $resource(url, null, actions);

		return {
			generate: function(reportConfig) {
				return resource.generate(reportConfig).$promise;
			}
		};
	}
})();
