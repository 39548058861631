import { AuthenticationManager, ClientId } from '@imaginelearning/web-auth';

angular
	.module('api.authentication.oidcUserManager', [])
	.constant('OidcUserManagerEvents', {
		userUnloaded: 'OidcUserManager.userUnloaded'
	})
	.provider('OidcUserManager', OidcUserManagerProvider);

function OidcUserManagerProvider() {
	let userManager;
	const provider = this;
	let settings = {
		authSource: 'imlp',
		env: 'test',
		claims: [],
		post_logout_redirect_uri: '',
		redirect_uri: ''
	};

	provider.configure = configure;
	provider.$get = $get;

	function configure(config) {
		settings = { ...settings, ...config };
	}

	$get.$inject = ['$timeout'];
	function $get($timeout) {
		userManager = new AuthenticationManager({
			environment: settings.env,
			client_id: settings?.client_id || ClientId.Portal, // default to client_id of 'Portal'
			claims: settings.claims,
			post_logout_redirect_uri: settings.post_logout_redirect_uri,
			redirect_uri: settings.redirect_uri
		});

		function initialize() {
			userManager.initialize();
		}

		function signinRedirect() {
			return $timeout(() => userManager.signinRedirect());
		}

		function signinRedirectCallback() {
			return $timeout(() => userManager.signinRedirectCallback());
		}

		function signoutRedirect() {
			return $timeout(() => userManager.signoutRedirect());
		}

		function checkSessionStorage() {
			return userManager.checkSessionStorage();
		}

		function checkLocalStorage() {
			return userManager.checkLocalStorage();
		}

		function checkUrl() {
			return userManager.checkUrl();
		}

		return {
			initialize,
			signinRedirect,
			signinRedirectCallback,
			signoutRedirect,
			checkSessionStorage,
			checkLocalStorage,
			checkUrl
		};
	}
}
