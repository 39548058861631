(function() {
	angular.module('report.queue', ['api.urls', 'utils.date', 'reporting.api.helpers', 'ngResource']).factory('ReportQueue', factory);

	factory.$inject = ['$rootScope', '$resource', '$filter', 'urls', 'ReportingAPIHelpers'];

	function factory($rootScope, $resource, $filter, urls, ReportingAPIHelpers) {
		var url = urls.reportsApiUrl + 'generate';

		var actions = {
			// Achievements
			available: {
				method: 'GET',
				url: url + '/available'
			},

			csvExportStart: {
				method: 'GET',
				url: url + '/csv'
			},

			csvExportDownload: {
				method: 'GET',
				url: url + '/download',
				transformResponse: ReportingAPIHelpers.transformTextResponse
			}
		};

		var resource = $resource(url, null, actions);

		return {
			csvExport: {
				available: function() {
					return resource.available().$promise;
				},

				start: function(params) {
					return resource.csvExportStart({
						orgId: params.id,
						authSource: $rootScope.getAuthSource()
					}).$promise;
				},

				download: function(params) {
					return resource.csvExportDownload({
						jobGuid: params.id,
						authSource: $rootScope.getAuthSource()
					});
				}
			}
		};
	}
})();
