(function() {
	'use strict';

	angular.module('api.logger').service('Logger', Logger);

	Logger.$inject = ['$http', 'ApplicationUserService', 'LogLevel', 'urls', 'version'];
	function Logger($http, ApplicationUserService, LogLevel, urls, version) {
		var url = urls.loggerApiUrl + 'logs/';

		this.log = log;

		////////////////

		function log(severity, message, stack, statusCode, requestedUrl, referrerUrl, service, details) {
			var user = ApplicationUserService.getUser(),
				username = (user && user.username) || 'Unknown',
				sitecode = user && user.siteCode,
				body = {
					Message: message || '',
					Severity: _.upperFirst(severity || LogLevel.log),
					Source: 'Portal',
					Stack: stack || '',
					TimeStamp: new Date().toISOString(), // Can't use `CurrentDate.get()` because of circular dependency
					Username: username,
					Version: version.get()
				};

			if (sitecode) {
				body.SiteCode = sitecode;
			}
			if (statusCode) {
				body.StatusCode = statusCode;
			}
			if (requestedUrl) {
				body.RequestedUrl = requestedUrl;
			}
			if (referrerUrl) {
				body.ReferrerUrl = referrerUrl;
			}
			if (service) {
				body.Service = service;
			}
			if (details) {
				body.Details = details;
			}

			return $http.post(url, [body], { headers: { 'Content-Type': 'application/json' } });
		}
	}
})();
