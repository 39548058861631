(function(angular) {
	angular.module('api.helpers', []).factory('APIHelpers', apiHelpersFactory);

	apiHelpersFactory.$inject = ['$filter'];

	function apiHelpersFactory($filter) {
		return {
			dateFilter: dateFilter,
			convertToCountAndItems: convertToCountAndItems,
			convertToCountAndItemsWithContToken: convertToCountAndItemsWithContToken,
			reportingAPIToCountAndItems: reportingAPIToCountAndItems,
			reportingAPIObjectToCountAndItems: reportingAPIObjectToCountAndItems
		};

		/**
		 * Helper response transformer that will take JSON data with a count header and convert
		 * it to {items: parsed data, totalCount: total count}
		 */
		function convertToCountAndItems(data, headersGetter) {
			var items;
			try {
				items = angular.fromJson(data);
			} catch (e) {
				return data;
			}
			var count = items ? items.length : 0;
			return {
				totalCount: +headersGetter('X-Total-Count') || count,
				items: items
			};
		}

		function convertToCountAndItemsWithContToken(data, headersGetter) {
			var items,
				headers = headersGetter();
			try {
				items = angular.fromJson(data);
			} catch (e) {
				return data;
			}
			return {
				totalCount: +headersGetter('x-total-count') || items.length || 0,
				continuationToken: headersGetter('x-continuation-token'),
				items: items
			};
		}

		function reportingAPIToCountAndItems(data) {
			var items, json;
			try {
				json = angular.fromJson(data);
				items = json.results;
			} catch (e) {
				return data;
			}

			return {
				totalCount: json.totalCount,
				items: items
			};
		}

		function reportingAPIObjectToCountAndItems(data) {
			var items;
			try {
				items = [angular.fromJson(data)];
			} catch (e) {
				return data;
			}

			return {
				totalCount: items.length,
				items: items
			};
		}

		function dateFilter(date) {
			return $filter('date')(date, 'yyyy-MM-dd');
		}
	}
})(window.angular);
