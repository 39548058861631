(function() {
	angular.module('report.printing', ['api.urls', 'product.names', 'utils.date', 'ngResource']).factory('ReportPrinting', factory);

	factory.$inject = ['$resource', '$filter', 'ApiProducts', 'urls'];

	function factory($resource, $filter, ApiProducts, urls) {
		function dateFilter(date) {
			return $filter('date')(date, 'yyyy-MM-dd');
		}

		var url = urls.reportsApiUrl + 'pdfreport',
			skillsBaseUrl = urls.reportApiUrl,
			apiUrl = urls.reportApiUrl,
			achievementCertificateUrl = url + '/AchievementCertificate',
			classRosterUrl = url + '/ClassRoster',
			usageUrl = url + '/usage',
			paramStrings = {
				all: 'all',
				english: 'en',
				spanish: 'sp'
			};

		var actions = {
			studentAchievements: {
				method: 'GET',
				url: achievementCertificateUrl,
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			groupAchievements: {
				method: 'GET',
				url: achievementCertificateUrl,
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			classRoster: {
				method: 'GET',
				url: classRosterUrl,
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			usage: {
				cancellable: true,
				method: 'GET',
				url: usageUrl,
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			organizationUsage: {
				cancellable: true,
				method: 'GET',
				url: apiUrl + 'organizations/:id/usage/pdf/',
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			groupUsage: {
				cancellable: true,
				method: 'GET',
				url: apiUrl + 'groups/:id/usage/pdf/',
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			studentUsage: {
				cancellable: true,
				method: 'GET',
				url: apiUrl + 'students/:id/usage/pdf/',
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			studentSkillsByCurriculumArea: {
				cancellable: true,
				method: 'GET',
				url: skillsBaseUrl + 'students/:id/skills/pdf',
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			studentSkillsByDate: {
				cancellable: true,
				method: 'GET',
				url: skillsBaseUrl + 'students/:id/skillsbydate/pdf',
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			groupSkillsByCurriculumArea: {
				cancellable: true,
				method: 'GET',
				url: skillsBaseUrl + 'groups/:id/skills/pdf',
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			groupSkillsByDate: {
				cancellable: true,
				method: 'GET',
				url: skillsBaseUrl + 'groups/:id/skillsbydate/pdf',
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			// internal reports
			partnership: {
				cancellable: true,
				method: 'GET',
				url: url + '/partnership',
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			},

			activityBrief: {
				cancellable: true,
				method: 'GET',
				url: url + '/activityBrief',
				responseType: 'arraybuffer',
				transformResponse: function(data) {
					return { data: data };
				}
			}
		};

		var resource = $resource(url, null, actions);

		return {
			achievementCertificates: {
				student: function(params) {
					return resource.studentAchievements({
						studentId: params.id,
						startdatemmddyyyy: dateFilter(params.startDate),
						enddatemmddyyyy: dateFilter(params.endDate)
					}).$promise;
				},

				group: function(params) {
					return resource.groupAchievements({
						groupId: params.id,
						startdatemmddyyyy: dateFilter(params.startDate),
						enddatemmddyyyy: dateFilter(params.endDate)
					}).$promise;
				}
			},

			classRosters: {
				organization: function(params) {
					return resource.classRoster({
						organizationId: params.id
					}).$promise;
				},

				group: function(params) {
					return resource.classRoster({
						groupId: params.id
					}).$promise;
				}
			},

			skills: {
				student: {
					byCurriculumArea: function(params) {
						return resource.studentSkillsByCurriculumArea({
							curriculumArea: params.curriculumArea || paramStrings.all,
							resultType: params.resultType || paramStrings.all,
							id: params.studentId,
							language: params.language || paramStrings.english,
							start: dateFilter(params.start),
							end: dateFilter(params.end),
							product: params.product ? params.product : ApiProducts.ILE
						});
					},

					byDate: function(params) {
						return resource.studentSkillsByDate({
							curriculumArea: params.curriculumArea || paramStrings.all,
							resultType: params.resultType || paramStrings.all,
							id: params.studentId,
							language: params.language || paramStrings.english,
							start: dateFilter(params.start),
							end: dateFilter(params.end),
							product: params.product ? params.product : ApiProducts.ILE
						});
					}
				},

				group: {
					byCurriculumArea: function(params) {
						return resource.groupSkillsByCurriculumArea({
							curriculumArea: params.curriculumArea || paramStrings.all,
							resultType: params.resultType || paramStrings.all,
							id: params.groupId,
							language: params.language || paramStrings.english,
							start: dateFilter(params.start),
							end: dateFilter(params.end),
							product: params.product ? params.product : ApiProducts.ILE
						});
					},

					byDate: function(params) {
						return resource.groupSkillsByDate({
							curriculumArea: params.curriculumArea || paramStrings.all,
							resultType: params.resultType || paramStrings.all,
							id: params.groupId,
							language: params.language || paramStrings.english,
							start: dateFilter(params.start),
							end: dateFilter(params.end),
							product: params.product ? params.product : ApiProducts.ILE
						});
					}
				}
			},

			coreUsage: {
				organization: function(params) {
					return resource.organizationUsage({
						id: params.id,
						intervalSize: params.intervalSize,
						start: dateFilter(params.start),
						end: dateFilter(params.end),
						grade: params.grade,
						product: params.product
					});
				},

				group: function(params) {
					return resource.groupUsage({
						id: params.id,
						intervalSize: params.intervalSize,
						start: dateFilter(params.start),
						end: dateFilter(params.end),
						grade: params.grade,
						product: params.product
					});
				},

				student: function(params) {
					return resource.studentUsage({
						id: params.id,
						intervalSize: params.intervalSize,
						start: dateFilter(params.start),
						end: dateFilter(params.end),
						product: params.product
					});
				}
			},

			usage: {
				organization: function(params) {
					return resource.usage({
						organizationId: params.id,
						weekDaysOnly: false,
						summaryPage: params.summaryPage,
						batchPages: params.batchPages,
						grade: params.grade,
						schoolyearstartmmddyyyy: dateFilter(params.startDate),
						enddatemmddyyyy: dateFilter(params.endDate)
					});
				},

				group: function(params) {
					return resource.usage({
						groupId: params.id,
						weekDaysOnly: false,
						summaryPage: params.summaryPage,
						batchPages: params.batchPages,
						grade: params.grade,
						schoolyearstartmmddyyyy: dateFilter(params.startDate),
						enddatemmddyyyy: dateFilter(params.endDate)
					});
				},

				student: function(params) {
					return resource.usage({
						studentId: params.id,
						weekDaysOnly: false,
						schoolyearstartmmddyyyy: dateFilter(params.startDate),
						enddatemmddyyyy: dateFilter(params.endDate)
					});
				}
			},

			// internal reports
			internalReports: {
				partnership: function(params) {
					return resource.partnership({
						orgId: params.orgId,
						dataFields: params.dataFields,
						schoolyearstartmmddyyyy: dateFilter(params.startDate),
						enddatemmddyyyy: dateFilter(params.endDate)
					});
				},

				activityBrief: function(params) {
					return resource.activityBrief({
						orgId: params.orgId,
						dataFields: params.dataFields,
						schoolyearstartmmddyyyy: dateFilter(params.startDate),
						enddatemmddyyyy: dateFilter(params.endDate)
					});
				}
			}
		};
	}
})();
