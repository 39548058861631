angular.module('api.username', ['api.urls', 'ngResource']).factory('UsernameService', usernameFactory);

usernameFactory.$inject = ['$resource', 'urls'];

function usernameFactory($resource, urls) {
	const resourceUrl = 'availability/username/';
	const url = urls.managementApiUrl + resourceUrl;
	const actions = {
		checkAvailability: {
			method: 'POST'
		}
	};

	let resource = $resource(url, null, actions);

	return {
		checkUserAvailability: function(usernames) {
			if (!_.isArray(usernames)) {
				usernames = [usernames];
			}
			return resource.checkAvailability({ usernames }).$promise;
		},
		checkStudentAvailability: function(usernames, siteCode) {
			if (!_.isArray(usernames)) {
				usernames = [usernames];
			}
			return resource.checkAvailability({ usernames, siteCode }).$promise;
		}
	};
}
