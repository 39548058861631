(function(angular, undefined) {
	angular.module('api.token', ['api.urls', 'ngResource']).factory('Token', tokenFactory);

	tokenFactory.$inject = ['$http', 'urls'];

	function tokenFactory($http, urls) {
		return {
			token: function(username, password) {
				return $http.post(
					urls.loginApiUrl + 'Token',
					'grant_type=password&username=' +
						encodeURIComponent(username) +
						'&password=' +
						encodeURIComponent(password) +
						'&client_id=Portal',
					{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
				);
			},
			impersonateILAuth: function(token, actAsUserName, resumeUrl) {
				return $http.post(
					urls.loginApiUrl + 'Token',
					'grant_type=impersonate&client_id=Portal&token=' +
						token +
						'&actas=' +
						encodeURIComponent(actAsUserName) +
						(resumeUrl ? '&resumeUrl=' + encodeURIComponent(resumeUrl) : ''),
					{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, withCredentials: true }
				);
			},
			impersonate: function(token, actAsUserId, resumeUrl) {
				return $http.post(
					urls.imlpApiUrl + '/connect/token',
					'grant_type=impersonate&client_id=Portal&token=' +
						token +
						'&actAs=' +
						encodeURIComponent(actAsUserId) +
						(resumeUrl ? '&resumeUrl=' + encodeURIComponent(resumeUrl) : ''),
					{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, withCredentials: true }
				);
			},
			ssoToken: function(encryptedToken, signature, ssoProvidedProduct, clientId = 'Portal') {
				let params =
					'grant_type=ssotoken' + '&client_id=' + clientId + '&encryptedToken=' + encryptedToken + '&signature=' + signature;
				if (ssoProvidedProduct) {
					params += '&ssoProvidedProduct=' + ssoProvidedProduct;
				}
				return $http.post(urls.loginApiUrl + 'Token', params, {
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					withCredentials: true
				});
			}
		};
	}
})(window.angular);
