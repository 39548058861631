(function() {
	angular
		.module('api.manager', [
			'api.manager.constants',
			'api.manager.helpers',
			'api.audit.history',
			'api.class',
			'api.iladmin',
			'api.organization',
			'api.password',
			'api.schoolYear',
			'api.siteCode',
			'api.student',
			'api.user',
			'api.search',
			'api.settings',
			'api.manager.bulk.operations',
			'api.username',
			'api.districtStudentId'
		])
		.factory('ManagerAPI', factory);

	factory.$inject = [
		'AuditHistory',
		'Organization',
		'Class',
		'User',
		'Student',
		'UsernameService',
		'DistrictStudentIdService',
		'managerAPIEntityTypes',
		'managerAPIHelpers'
	];

	function factory(
		AuditHistory,
		Organization,
		Class,
		User,
		Student,
		UsernameService,
		DistrictStudentIdService,
		managerAPIEntityTypes,
		managerAPIHelpers
	) {
		var managerAPI = this;

		managerAPI.AuditHistory = AuditHistory;
		managerAPI.Organization = Organization;
		managerAPI.Group = Class;
		managerAPI.User = User;
		managerAPI.Student = Student;
		managerAPI.UsernameService = UsernameService;
		managerAPI.DistrictStudentIdService = DistrictStudentIdService;

		managerAPI.entityTypes = managerAPIEntityTypes;
		managerAPI.helpers = managerAPIHelpers;

		return managerAPI;
	}
})();
