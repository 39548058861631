(function() {
	'use strict';

	angular
		.module('api.authentication.constants', [])
		.constant('DEFAULT_LOCATION', '/dashboard')
		.constant('TOKEN_ROLE', 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role')
		.constant('TOKEN_USERNAME', 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name')
		.constant('TOKEN_NAMEIDENTIFIER', 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier')
		.constant('TOKEN_SITECODE', 'https://imaginelearning.com/claims/sitecodes')
		.constant('TOKEN_DISPLAYNAME', 'https://imaginelearning.com/claims/displayname')
		.constant('AUTH_CORE_JWT', {
			// standard
			exp: 'exp',
			sub: 'sub',
			name: 'name',
			email: 'email',
			role: 'role',
			client_id: 'client_id',
			given_name: 'given_name',
			family_name: 'family_name',
			amr: 'amr',
			// custom
			id: 'http://il/id',
			username: 'http://il/username',
			impersonation: 'http://il/impersonation',
			sites: 'http://il/sites',
			firstLanguage: 'http://il/firstLanguage',
			writtenLanguage: 'http://il/writtenLanguage',
			gradeLevel: 'http://il/grade',
			products: 'http://il/products',
			orgPaths: 'http://il/orgPaths',
			organizations: 'http://il/orgs'
		});
})();
