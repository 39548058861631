(function(angular, undefined) {
	angular.module('api.password', ['ngResource', 'api.urls']).factory('PasswordAPI', passwordApiFactory);

	passwordApiFactory.$inject = ['$resource', 'urls'];

	function passwordApiFactory($resource, urls) {
		var url = urls.managementApiUrl;

		var resource = $resource(url, null, {
			sendResetEmail: {
				method: 'POST',
				url: url + 'passwordReset'
			},
			isResetTokenValid: {
				method: 'GET',
				url: url + 'passwordReset/:token'
			},
			resetPassword: {
				method: 'POST',
				url: url + 'passwordReset/:token',
				params: { token: '@token' }
			}
		});

		return {
			sendResetEmail: function(emailAddress) {
				return resource.sendResetEmail({
					username: emailAddress
				}).$promise;
			},

			/** Retruns the username if the token is valid, else false. */
			isResetTokenValid: function(token) {
				return resource
					.isResetTokenValid({
						token: token
					})
					.$promise.then(
						function(data) {
							return data.username;
						},
						function(req) {
							if (req.status === 404) {
								return false;
							} else {
								throw req;
							}
						}
					);
			},

			resetPassword: function(token, newPassword) {
				return resource.resetPassword({
					token: token,
					password: newPassword
				}).$promise;
			}
		};
	}
})(window.angular);
