(function() {
	'use strict';

	angular.module('api.logger').service('loggerHandler', LoggerHandler);

	LoggerHandler.$inject = ['$rootScope', '$window', 'Logger'];
	function LoggerHandler($rootScope, $window, Logger) {
		this.on = on;
		this.off = off;

		// Exposed for testing
		this._ = {
			parse: parse,
			getServiceName: getServiceName
		};

		////////////////

		function on() {
			$window.addEventListener('Logger.Log', onLog, false);
		}

		function off() {
			$window.removeEventListener('Logger.Log', onLog, false);
		}

		function onLog(event) {
			var detail = event.detail || {},
				data = parse(detail.log),
				service = getServiceName(data.requestedUrl),
				referrerUrl = $window.location.href;

			Logger.log(
				detail.severity,
				data.message,
				data.stack || detail.stack,
				data.statusCode,
				data.requestedUrl,
				referrerUrl,
				service,
				data.details
			).catch(function(error) {
				// Intentionally bypassing the $log service here, so this doesn't get run back through the $log decorators
				console.error('Error logging to Logger API:', error);
			});
		}

		/**
		 * Parses the arguments passed to a logging function for parameters to send to the Logger API
		 *
		 * @param {Array} logArgs - Array of arguments passed to logging function
		 * @return {Object} - An object with the following properties: message, stack, statusCode, requestedUrl
		 */
		function parse(logArgs) {
			if (!logArgs) {
				return {};
			}

			var data = {},
				error = logArgs
					.filter(function(arg) {
						return arg instanceof Error;
					})
					.shift(),
				httpError = logArgs
					.filter(function(arg) {
						return (
							typeof arg === 'object' &&
							arg.hasOwnProperty('data') &&
							arg.hasOwnProperty('config') &&
							arg.hasOwnProperty('status')
						);
					})
					.shift();

			var message = logArgs
				.map(function(arg) {
					if (!arg) {
						return arg;
					}
					if (typeof arg === 'string') {
						return arg;
					}
					if (typeof arg === 'object') {
						if (arg instanceof Error) {
							return arg.message;
						}
						if (arg.data && arg.data.message) {
							return arg.data.message;
						}
						if (arg.statusText) {
							return arg.statusText;
						}
						return JSON.stringify(arg);
					}
					return arg.toString();
				})
				.join(' ');

			data.message = message;
			data.stack = error && error.stack;
			data.statusCode = (httpError && httpError.status) || (error && error.status);
			data.requestedUrl = httpError && httpError.config && httpError.config.url;
			if (httpError) {
				data.details = JSON.stringify(httpError);
			} else if (error) {
				data.details = JSON.stringify(
					Object.getOwnPropertyNames(error).reduce(function(aggregator, prop) {
						aggregator[prop] = error[prop];
						return aggregator;
					}, {})
				);
			}

			return data;
		}

		function getServiceName(url) {
			var parts = (url || '').match(/http[s]?:\/\/((test|load|rc|demo)?(manager|sequencer|reports)?[^\/]*)/i);
			if (!parts) {
				return '';
			}

			var name = parts
				.slice(2, 4)
				.filter(function(part) {
					return !!part;
				})
				.map(function(part) {
					return _.upperFirst(part || '');
				})
				.join(' ');

			if (!name) {
				name = parts.slice(1, 2).shift();
			}

			return name || '';
		}
	}
})();
