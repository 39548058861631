import * as Pendo from 'PendoService';

(function() {
	angular.module('api.pendo', ['api.application.user']).service('PendoService', service);

	service.$inject = ['$window', '$timeout', 'ApplicationUserService'];

	function service($window, $timeout, ApplicationUserService) {
		this.pendoService = new Pendo.PendoService();
		this.user = {};
		// this.initialized = false;

		// methods
		this.identify = identify;
		this.initPendo = initPendo;
		this.removeExistingPendoInstance = removeExistingPendoInstance;

		// this resolves a unique issue we will only have switching between L&L & Esp portals as they are both in the same SPA
		function removeExistingPendoInstance() {
			var scripts = document.getElementsByTagName('script');
			for (var i = scripts.length; i >= 0; i--) {
				if (scripts[i] && scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').indexOf('pendo') != -1) {
					scripts[i].parentNode.removeChild(scripts[i]);
				}
			}
			if ($window.pendo) {
				delete $window.pendo;
			}
		}

		var timeout;
		function identify(pendoConfig) {
			if (!!pendoConfig && ApplicationUserService.isAuthenticated() && ApplicationUserService.getRootOrganization() !== undefined) {
				if (timeout) {
					$timeout.cancel(timeout);
				}
				timeout = $timeout(() => {
					this.initPendo(pendoConfig);
				}, 300);
			}
		}

		function initPendo(pendoConfig) {
			if ($window.pendo) {
				this.removeExistingPendoInstance();
			}
			this.user = ApplicationUserService.getUser();
			this.user.organization = ApplicationUserService.getRootOrganization();

			const identityUser = { ...this.user };
			if (!!identityUser.impersonationDetails) {
				const impersonationDetails = {
					actualUser: identityUser.impersonation.ilUser,
					impersonatedUser: identityUser.impersonation.user
				};
				identityUser.impersonationDetails = impersonationDetails;
			}

			const identity = this.pendoService.getAuthUserPendoIdentity(identityUser);
			this.pendoService.initialize(pendoConfig, identity);
		}
	}
})();
